import { configureStore } from '@reduxjs/toolkit'
import userReducer from './users'
import templateReducer from './template'
import itemReducer from './items'
import orderReducer from './orders'
import tituloReducer from './titulos'
import promotionReducer from './promotions'
import conditionsReducer from './conditions'
import shoppingCartReducer from './shopping-cart'
import brandsSliceReducer from './brands'

export default configureStore({
  reducer: {
    users: userReducer,
    template: templateReducer,
    items: itemReducer,
    promotions: promotionReducer,
    orders: orderReducer,
    titulos: tituloReducer,
    conditions: conditionsReducer,
    shoppingCart: shoppingCartReducer,
    brands: brandsSliceReducer
  }
})
