import { FilterCheckboxesContainer } from '../categories/styles'
import {
  FilterCheckboxes,
  FilterContainer,
  InputCategory
} from './styles'

export function ProductBrandsModal({
  show,
  productsBrands,
  handleChangeBrands,
  handleClose,
  loading
}) {
  return (
    <>
      {show && (
        <div
          onClick={handleClose}
          style={{
            background: 'black',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0
          }}
        />
      )}

      <FilterContainer show={show}>
          <h3>Marcas ({productsBrands.length})</h3>
          <FilterCheckboxes>
            <FilterCheckboxesContainer>
              {productsBrands.map((brand, index) => {
                return (
                  <span key={index}>
                    <InputCategory>
                      <div
                        onClick={() => handleChangeBrands(brand)}
                        disabled={loading}
                      >{`${brand.MarcasDescri}`}</div>
                    </InputCategory>
                  </span>
                )
              })}
            </FilterCheckboxesContainer>
          </FilterCheckboxes>
      </FilterContainer>
    </>
  )
}
