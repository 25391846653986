export class StoreOrderService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(data) {
    return await this.httpClient.post('/pedidos/NovoPedidoB2B', {
      NovoPedido: data
    })
  }
}
