import { useProducts } from 'application/contexts/products'
import { handleDescriptionService } from 'application/usecases/handle-description'
import { handleListCarBrand } from 'application/usecases/handle-list-car-brand'
import { handleListCarModel } from 'application/usecases/handle-list-car-model'
import { handleListItem } from 'application/usecases/handle-list-item'
import { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, Container, Modal, SelectLabel, Spinner } from 'view/components'
import { Announcement } from 'view/components/Announcement/Announcement'
import { ProductBrandsModal } from 'view/components/brands'
import { CardProductDefault } from 'view/components/card-product-default/CardProductDefault'
import { Categories } from 'view/components/categories'
import { Skeleton } from 'view/components/Skeleton'
import { yearOptionsList } from 'view/helpers'
import * as S from './styles'

export const ListItemPage = () => {
  const conditions = useSelector((state) => state.conditions)
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [carsModels, setCarsModels] = useState([])
  const [brands, setBrands] = useState([])
  const [productsBrands, setProductsBrands] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [productBrand, setProductBrand] = useState('')
  const [model, setModel] = useState('')
  const [year, setYear] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [subcategorySelected, setSubcategorySelected] = useState('')
  const [brandSelected, setBrandSelected] = useState('')
  const [showCategoriesList, setShowCategoriesList] = useState(true)
  const [showBrandsList, setShowBrandsList] = useState(true)
  const [showCategoriesModal, setShowCategoriesModal] = useState(false)
  const [showProductsBrandsModal, setShowProductBrandsModal] = useState(false)
  const [showCarsModal, setShowCarsModal] = useState(false)
  const [done, setDone] = useState(false)
  const [page, setPage] = useState(1)
  const [isPromotion, setIsPromotion] = useState(false)
  const { loading, createSignal, conclude } = useProducts()
  const yearOptions = yearOptionsList()
  const navigate = useNavigate()
  const location = useLocation()
  const pageSize = 20
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const productsFiltered = products?.filter((product) => {
    const brandsFiltered = productsBrands.filter((brand) => brand.check)
    if (!brandsFiltered.length) return product

    if (
      brandsFiltered.map((brand) => brand.label).includes(product?.ItensModelo)
    )
      return product

    return false
  })

  useEffect(() => {
    handleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (year && productBrand) handleListCarsModels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, productBrand])

  const handleScrollUp = () => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  const handleList = async () => {
    const controllerSignal = createSignal()
    const params = new URLSearchParams(location.search)
    const CategProdId = params.get('categoria')
    const SubcategId = params.get('subcategoria')
    const ModelosId = params.get('marca')
    const Filtro = params.get('descricao')
    const ItensCodFornec = params.get('codigo')
    const Pagenumber = params.get('pagina')
    const veiculoid = params.get('veiculo')
    const promocao = params.get('promocao')
    const anuncio = params.get('anuncio')
    const newParams = {
      Categprodid: CategProdId ?? 0,
      Subcategid: SubcategId ?? 0,
      ModelosId: ModelosId ?? '',
      Filtro: Filtro ?? '',
      Itenscodfornec: ItensCodFornec ?? '',
      Pagesize: pageSize,
      Pagenumber: Pagenumber ?? 1,
      veiculoid: veiculoid ?? '',
      promocao: promocao ?? '',
      anuncio: (!!promocao || anuncio) ?? false
    }

    setDone(false)

    if (CategProdId) {
      try {
        await getCategory(CategProdId)
      } catch (err) {
        console.error(err)
      }
    } else setCategorySelected('')

    if (SubcategId) {
      try {
        await getSubcategory(SubcategId)
      } catch (err) {
        console.error(err)
      }
    } else setSubcategorySelected('')

    if (ModelosId) {
      try {
        await getBrandDescription(ModelosId)
      } catch (err) {
        console.error(err)
      }
    } else setBrandSelected('')

    if (veiculoid) {
      try {
        await getVeiculo(veiculoid)
      } catch (err) {
        console.error(err)
      }
    } else {
      setProductBrand('')
      setModel('')
      setYear('')
    }

    if (promocao || anuncio) setIsPromotion(true)
    else setIsPromotion(false)

    try {
      const {
        data: { ListaItens, TotalItens }
      } = await handleListItem.handle(
        controllerSignal,
        {
          ...newParams,
          Clientesid: Number(token?.clientsId),
          Gruposid: 0
        },
        promocao ?? false,
        (!!promocao || anuncio) ?? false
      )

      setPage(newParams.Pagenumber)
      setProducts(ListaItens ?? [])
      setTotalItems(TotalItens ?? 0)
      setDone(true)
    } catch (error) {
      console.error(error)

      setPage(1)
      setProducts([])
      setTotalItems(0)
      setDone(false)
    }

    try {
      const categoriesList = await handleListItem.categories(controllerSignal, {
        ...newParams,
        Clientesid: Number(
          JSON.parse(localStorage.getItem('@barrosAuth'))?.clientsId
        ),
        Promotipoid: promocao,
        Gruposid: 0
      })

      setCategories(
        categoriesList?.data?.ListaCategorias?.map((categories) => ({
          ...categories,
          show: false
        })) ?? []
      )
    } catch (error) {
      console.error(error)

      setCategories([])
    }

    try {
      const productsBrandsList = await handleListItem.brands(controllerSignal, {
        ...newParams,
        Clientesid: Number(
          JSON.parse(localStorage.getItem('@barrosAuth'))?.clientsId
        ),
        Promotipoid: promocao,
        Gruposid: 0
      })

      const newProductsBrandsList =
        productsBrandsList?.data?.ListaMarcas?.map((brand) => ({
          ...brand,
          check: false
        })) ?? []

      setProductsBrands(newProductsBrandsList)
    } catch (error) {
      console.error(error)

      setProductsBrands([])
    }

    if (!brands.length)
      try {
        const {
          data: { RetMarcas }
        } = await handleListCarBrand.handle(controllerSignal)

        setBrands(RetMarcas ?? [])
      } catch (error) {
        console.error(error)

        setBrands([])
      }

    conclude()
  }

  const getCategory = async (id) => {
    const { data } = await handleDescriptionService.category({
      Categid: id
    })

    if (!data?.CategDescri) throw new Error('Categoria não encontrada')
    setCategorySelected(data?.CategDescri)
  }

  const getSubcategory = async (id) => {
    const { data } = await handleDescriptionService.subcategory({
      Subcategid: id
    })

    if (!data?.SubCategDescri) throw new Error('SubCategoria não encontrada')
    setSubcategorySelected(data?.SubCategDescri)
  }

  const getBrandDescription = async (id) => {
    const { data } = await handleDescriptionService.brand({
      ModelosId: id
    })

    if (!data?.ModelosDescri) throw new Error('Marca não encontrada')
    setBrandSelected(data?.ModelosDescri)
  }

  const getVeiculo = async (id) => {
    const { data } = await handleDescriptionService.veiculo({
      Veiculoid: id
    })

    if (!data?.VeiculoDescri) throw new Error('Veículo não encontrado')
    setModel(data?.VeiculoDescri)
  }

  const handleListCarsModels = async () => {
    try {
      const {
        data: { RetVeiculos }
      } = await handleListCarModel.handle({
        Ano: year,
        Cmarca: productBrand
      })

      setCarsModels(RetVeiculos ?? [])
    } catch (error) {
      toast.error('Não foi possível buscar os modelos dos veículos.')
    }
  }

  // const handleChangePage = (newPage) => {
  //   const params = new URLSearchParams(location.search)

  //   handleScrollUp()

  //   setPage(newPage)

  //   params.set('pagina', newPage)
  //   navigate(`?${params.toString()}`)
  // }

  const handleChangeCategory = (category) => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setShowProductBrandsModal(false)
    setCategorySelected(category.ItensCategDescri)
    handleScrollUp()

    params.set('categoria', category.ItensCategId)
    navigate(`?${params.toString()}`)
  }

  const handleChangeSubcategory = (subcategory) => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setSubcategorySelected(subcategory.ItensSubCategDescri)
    handleScrollUp()

    params.set('subcategoria', subcategory.ItensSubCategId)
    navigate(`?${params.toString()}`)
  }

  const handleChangeBrands = (brand) => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setShowProductBrandsModal(false)
    setCategorySelected(brand?.MarcasDescri)
    handleScrollUp()

    params.set('marca', brand.MarcaId)
    navigate(`?${params.toString()}`)
  }

  const handleChangeCarModel = (newModel) => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    handleScrollUp()

    params.set('veiculo', newModel)
    navigate(`?${params.toString()}`)
  }

  const handleClearPromotion = () => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setIsPromotion(false)
    handleScrollUp()

    params.delete('promocao')
    params.delete('anuncio')
    params.delete('marca')
    navigate(`?${params.toString()}`)
  }

  const handleClearCategory = () => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setCategorySelected('')
    handleScrollUp()

    params.delete('categoria')
    navigate(`?${params.toString()}`)
  }

  const handleClearSubcategory = () => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setSubcategorySelected('')
    handleScrollUp()

    params.delete('subcategoria')
    navigate(`?${params.toString()}`)
  }

  const handleClearBrandSelected = () => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setBrandSelected('')
    handleScrollUp()

    params.delete('marca')
    navigate(`?${params.toString()}`)
  }

  const handleClearCarModel = () => {
    const params = new URLSearchParams(location.search)

    setPage(1)
    setModel('')
    handleScrollUp()

    params.delete('veiculo')
    navigate(`?${params.toString()}`)
  }

  const handleClickToOpenCategories = () => {
    setShowCategoriesModal(!showCategoriesModal)
    setShowProductBrandsModal(false)
  }

  const handleClickToOpenProductBrands = () => {
    setShowProductBrandsModal(!showProductsBrandsModal)
    setShowCategoriesModal(false)
  }

  const handleToggleShowSubcategories = (index) => {
    setCategories((prevState) => {
      const newState = [...prevState]
      newState[index].show = !newState[index].show
      return newState
    })
  }

  return (
    <Container>
      <div>
        {/* <span style={{ display: 'flex', width: '100%' }}> */}
        <S.Title>
          <S.ButtonToMobileCategories
            onClick={handleClickToOpenCategories}
            disabled={loading}
          >
            Categorias
            {loading && <Spinner borderSize={2} size={16} />}
          </S.ButtonToMobileCategories>
          <S.ButtonToMobileCategories
            onClick={handleClickToOpenProductBrands}
            disabled={loading}
          >
            Marcas
            {loading && <Spinner borderSize={2} size={16} />}
          </S.ButtonToMobileCategories>

          <Categories
            show={showCategoriesModal}
            categories={categories}
            productsLength={products?.length ?? 0}
            handleClose={() => setShowCategoriesModal(false)}
            handleChangeCategory={handleChangeCategory}
            handleChangeSubcategory={handleChangeSubcategory}
            setShowCarsModal={setShowCarsModal}
            loading={loading}
          />

          <ProductBrandsModal
            show={showProductsBrandsModal}
            productsBrands={productsBrands}
            handleChangeBrands={handleChangeBrands}
            handleClose={() => setShowProductBrandsModal(false)}
            loading={loading}
          />
        </S.Title>

        <S.FiltersSelected>
          <p>Filtros Selecionados:</p>
          <S.FilterItems>
            {isPromotion && (
              <S.FilterItem>
                Produtos em promoção
                <FaTimes onClick={handleClearPromotion} />
              </S.FilterItem>
            )}

            {categorySelected && (
              <S.FilterItem>
                {categorySelected}
                <FaTimes onClick={handleClearCategory} />
              </S.FilterItem>
            )}

            {subcategorySelected && (
              <S.FilterItem>
                {subcategorySelected}
                <FaTimes onClick={handleClearSubcategory} />
              </S.FilterItem>
            )}

            {brandSelected && (
              <S.FilterItem>
                {brandSelected}
                <FaTimes onClick={handleClearBrandSelected} />
              </S.FilterItem>
            )}

            {model && (
              <S.FilterItem>
                {model}
                <FaTimes onClick={handleClearCarModel} />
              </S.FilterItem>
            )}
          </S.FilterItems>
        </S.FiltersSelected>

        <p style={{ fontSize: 14, marginTop: 10 }}>{`Exibindo ${
          (page ?? 1) * 20 - 20
        }-${products?.length ?? 0} de ${totalItems ?? 0} produtos`}</p>

        {!isPromotion && <Announcement />}

        <S.ContentPage>
          <S.Filter>
            {/* <Button
              color="--color-white"
              colorFont="--color-dark-grey"
              hoverColor="#C41104"
              onClick={() => setShowCarsModal(true)}
              block
              disabled={!products.length || loading}
            >
              Selecione seu veículo
            </Button> */}
            <h3 onClick={() => setShowCategoriesList(!showCategoriesList)}>
              Categorias ({categories?.length})
              {loading && <Spinner borderSize={2} size={16} />}
            </h3>
            {showCategoriesList && (
              <S.FilterCheckboxes>
                {categories.map((category, index) => {
                  return (
                    <span key={index}>
                      <S.InputCategory>
                        <div>
                          {category.show ? (
                            <MdKeyboardArrowUp
                              onClick={() =>
                                handleToggleShowSubcategories(index)
                              }
                              size={20}
                            />
                          ) : (
                            <MdKeyboardArrowDown
                              onClick={() =>
                                handleToggleShowSubcategories(index)
                              }
                              size={20}
                            />
                          )}
                          <span
                            onClick={() => handleChangeCategory(category)}
                            disabled={loading}
                          >{`${category?.ItensCategDescri}`}</span>
                        </div>
                      </S.InputCategory>

                      {category?.show && (
                        <S.SubCategoryList>
                          {category?.ListaSubCategorias &&
                            category?.ListaSubCategorias.map(
                              (subcategory, index) => (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleChangeSubcategory(subcategory)
                                  }
                                  disabled={loading}
                                >{`${subcategory?.ItensSubCategDescri}`}</div>
                              )
                            )}
                        </S.SubCategoryList>
                      )}
                    </span>
                  )
                })}
              </S.FilterCheckboxes>
            )}

            <h3 onClick={() => setShowBrandsList(!showBrandsList)}>
              Marcas ({productsBrands.length})
              {loading && <Spinner borderSize={2} size={16} />}
            </h3>
            {showBrandsList && (
              <S.FilterCheckboxes>
                {productsBrands
                  .sort((a, b) =>
                    a.MarcasDescri < b.MarcasDescri
                      ? -1
                      : a.MarcasDescri > b.MarcasDescri
                      ? 1
                      : 0
                  )
                  .map((brand, index) => {
                    return (
                      <span key={index}>
                        <S.SubCategoryList>
                          <div
                            onClick={() => handleChangeBrands(brand)}
                          >{`${brand.MarcasDescri}`}</div>
                        </S.SubCategoryList>
                      </span>
                    )
                  })}
              </S.FilterCheckboxes>
            )}
          </S.Filter>
          <div style={{ width: '100%', minHeight: '90vh' }}>
            {loading ? (
              <S.ProductsContainer>
                {new Array(10).fill(0).map((_, index) => (
                  <Skeleton key={index} w="260px" h="550px" m="1px" />
                ))}
              </S.ProductsContainer>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <S.ProductsContainer>
                    {productsFiltered
                      .sort((curr, acc) => {
                        return (
                          Number(acc?.ItensSaldo) - Number(curr?.ItensSaldo)
                        )
                      })
                      .map((product, index) => {
                        return (
                          <CardProductDefault
                            key={index}
                            product={product}
                            conditionPercentage={
                              conditions?.list.length && conditions?.selected
                                ? conditions?.list.find((c) => {
                                    return c?.cPagtoId === conditions?.selected
                                  })?.pPercentual ?? 0
                                : 0
                            }
                            minValue={
                              conditions?.list.length && conditions?.selected
                                ? conditions?.list.find((c) => {
                                    return c?.cPagtoId === conditions?.selected
                                  })?.vValorMin ?? 0
                                : 0
                            }
                          />
                        )
                      })}
                  </S.ProductsContainer>
                </div>
                {!done && !loading && (
                  <S.NoProducts>
                    <img src="/nothing-found.png" alt="Não encontrado" />
                    <p>Nenhum produto encontrado.</p>
                  </S.NoProducts>
                )}
              </>
            )}

            {/* <Pagination
              page={page ?? 1}
              pageSize={pageSize}
              totalItems={totalItems}
              onChangePage={handleChangePage}
            /> */}
          </div>
        </S.ContentPage>
      </div>
      <Modal
        visibleModal={showCarsModal}
        setVisibleModal={() => {
          setShowCarsModal(false)
          setYear('')
          setProductBrand('')
        }}
      >
        <Card>
          <S.ModalContent>
            <SelectLabel
              label="Ano"
              name="year"
              onChange={(e) => setYear(e.target.value)}
            >
              <option>Selecione...</option>
              {yearOptions.map((year, idx) => (
                <option key={idx} value={year.year}>
                  {year.year}
                </option>
              ))}
            </SelectLabel>
            {year && (
              <SelectLabel
                label="Marca"
                name="brandId"
                onChange={(e) => {
                  setProductBrand(e.target.value)
                  setModel('')
                }}
              >
                <option>Selecione...</option>
                {brands
                  .sort((a, b) => {
                    if (a.dMarca < b.dMarca) return -1
                    if (a.dMarca > b.dMarca) return 1
                    return 0
                  })
                  .map((brand, index) => (
                    <option key={index} value={brand.cMarca}>
                      {brand.dMarca}
                    </option>
                  ))}
              </SelectLabel>
            )}
            {productBrand && (
              <SelectLabel
                label="Modelo"
                name="carModelId"
                onChange={(e) => {
                  handleChangeCarModel(e.target.value)

                  const model = carsModels.find(
                    (model) => model.cModelo === e.target.value
                  )

                  setModel(model.dMarca)
                  setShowCarsModal(false)
                  setYear('')
                  setProductBrand('')
                }}
              >
                <option>Selecione...</option>
                {carsModels &&
                  carsModels
                    .sort((a, b) => {
                      if (a.dMarca < b.dMarca) return -1
                      if (a.dMarca > b.dMarca) return 1
                      return 0
                    })
                    .map((car, index) => (
                      <option key={index} value={car.cModelo}>
                        {car.dMarca}
                      </option>
                    ))}
              </SelectLabel>
            )}
          </S.ModalContent>
        </Card>
      </Modal>
    </Container>
  )
}
