import { createSlice } from '@reduxjs/toolkit'

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: {
    amount: 0
  },
  reducers: {
    setAmount: (state, action) => {
      state.amount = action.payload
    }
  }
})

export const { setAmount } = shoppingCartSlice.actions

export default shoppingCartSlice.reducer
