import { Outlet } from 'react-router-dom'
import { Nav } from './topbar/TopBar'
import { FooterBar } from './footer/FooterBar'
import { OutletContainer } from './styles'
import { useEffect, useState } from 'react'
import { handleListOrder } from 'application/usecases/handle-list-order'
import Cookies from 'js-cookie'
import { NotificationCard, NotificationModal } from './notification-modal'

export const Layout = () => {
  const [showNotification, setShowNotification] = useState(false)
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))

  useEffect(() => {
    const ready = Cookies.get('@BarrosNotificationReady')
    if (ready === 'true') return

    getOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOrders = async () => {
    return await handleListOrder
      .handle({
        cCliente: Number(token?.clientsId)
      })
      .then((res) => {
        if (!res.data.Pedido || !res.data.cRegRetorno) throw new Error()

        const yerstardaysOrder = res.data.Pedido.filter((order) => {
          const lastDate = getLastDate()

          if (
            new Date(order.dtEmissao ?? '').toDateString() ===
              new Date(lastDate).toDateString() &&
            order.dFases === 'Orçamento'
          )
            return true

          return false
        })

        if (yerstardaysOrder.length) {
          setShowNotification(true)
          Cookies.set('@BarrosNotificationReady', true, {
            expires: 1
          })
        }
      })
      .catch((err) => {
        console.error(err)
        console.error('Não foi possível obter a lista de pedidos')
      })
  }

  const getLastDate = () => {
    const todayDate = new Date()

    const lastDate = new Date(todayDate.getTime())
    lastDate.setDate(todayDate.getDate() - 1)

    let dd = lastDate.getDate()
    let mm = lastDate.getMonth() + 1
    const yyyy = lastDate.getFullYear()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    return new Date(yyyy + '-' + mm + '-' + dd)
  }

  const handleCloseNotificationModal = () => {
    setShowNotification(false)
  }

  return (
    <>
      <Nav />
      <NotificationModal show={showNotification}>
        <NotificationCard handleClose={handleCloseNotificationModal} />
      </NotificationModal>
      <OutletContainer>
        <Outlet />
        <FooterBar />
      </OutletContainer>
    </>
  )
}
