import * as S from './styles'

export const Button = ({
  children,
  color,
  colorFont,
  borderColor,
  fontSize,
  block,
  onClick,
  small,
  type,
  hoverColor,
  ...other
}) => {
  return (
    <S.Button
      type={type}
      color={color}
      colorFont={colorFont}
      borderColor={borderColor}
      fontSize={fontSize}
      block={block}
      onClick={onClick}
      small={small}
      hoverColor={hoverColor}
      {...other}
    >
      {children}
    </S.Button>
  )
}
