import styled from 'styled-components'

export const Spinner = styled.div`
  border-radius: 50%;
  animation: spin 1s ease infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export const BigSpinner = styled(Spinner)`
  width: 70px;
  height: 70px;
  border: 10px solid #ccc;
  border-top: 10px solid var(--color-primary);
`
export const SmallSpinner = styled(Spinner)`
  width: ${(props) => props.size ?? 20}px;
  height: ${(props) => props.size ?? 20}px;
  position: ${(props) => (props.absolute ? 'absolute' : 'block')};
  border: ${(props) => props.borderSize ?? 6}px solid #e6e6e6;
  border-top: ${(props) => props.borderSize ?? 6}px solid var(--color-primary);
  top: ${(props) =>
    props.size ? 'calc(50% - ' + props.size / 2 + 'px)' : 'calc(50% - 10px)'};
  left: ${(props) =>
    props.size ? 'calc(50% - ' + props.size / 2 + 'px)' : 'calc(50% - 10px)'};
`

export const Wrapper = styled.div`
  /* position: absolute; */
  /* left: 50%; */
  /* top: 50%; */
  display: flex;
  align-items: center;
  height: 100%;
`
