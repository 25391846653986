import { createSlice } from '@reduxjs/toolkit'

export const promotionSlice = createSlice({
  name: 'promotions',
  initialState: {
    filter: '',
    itemsList: [],

    categoryId: 0,
    subcategoryId: 0,
    itemCategoriesList: [],

    veiculoId: 0,
    itemCarModal: false,

    itemListDone: false,
    itemError: '',

    page: 1,
    itemTotalItems: 0,

    promotionTypeId: '',
    promotionName: ''
  },
  reducers: {
    setCategoryId: (state, action) => {
      state.categoryId = action.payload
    },
    setSubcategoryId: (state, action) => {
      state.subcategoryId = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setVeiculoId: (state, action) => {
      state.veiculoId = action.payload
    },
    setItemsList: (state, action) => {
      state.itemsList = action.payload
    },
    setItemCategoriesList: (state, action) => {
      state.itemCategoriesList = action.payload
    },
    setItemTotalItems: (state, action) => {
      state.itemTotalItems = action.payload
    },
    setItemListDone: (state, action) => {
      state.itemListDone = action.payload
    },
    setItemError: (state, action) => {
      state.itemError = action.payload
    },
    setItemCarModal: (state, action) => {
      state.itemCarModal = action.payload
    },
    setItemPromotionTypeId: (state, action) => {
      state.promotionTypeId = action.payload
    },
    setItemPromotionName: (state, action) => {
      state.promotionName = action.payload
    }
  }
})

export const {
  setCategoryId,
  setSubcategoryId,
  setFilter,
  setPage,
  setVeiculoId,
  setItemsList,
  setItemCategoriesList,
  setItemTotalItems,
  setItemListDone,
  setItemError,
  setItemCarModal,
  setItemPromotionName
} = promotionSlice.actions

export default promotionSlice.reducer
