import styled from 'styled-components'

export const Footer = styled.footer`
  background-color: var(--color-primary);
  color: var(--color-white);
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
  width: 100%;
`
