import { createContext, useContext, useState } from 'react'

const ProductsContext = createContext()

export const useProducts = () => useContext(ProductsContext)

export const ProductsProvider = ({ children }) => {
  const [controllerSignal, setControllerSignal] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleConclude = () => {
    setControllerSignal(null)
    setLoading(false)
  }

  const handleCreateSignal = () => {
    const controllerSignal = new AbortController()

    setLoading(true)
    setControllerSignal(controllerSignal)

    return controllerSignal
  }

  const handleAbort = () => {
    console.log('Abortando', controllerSignal)

    if (controllerSignal) {
      controllerSignal.abort()
      setControllerSignal(null)
      setLoading(false)
    }
  }

  return (
    <ProductsContext.Provider
      value={{
        controllerSignal,
        loading,
        conclude: handleConclude,
        createSignal: handleCreateSignal,
        abort: handleAbort
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
