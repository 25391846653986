import { Card } from 'view/components'
import { Stack } from 'view/components/Stack'
import {
  NotificationButton,
  NotificationCardContainer,
} from './styles'

export const NotificationFreightType = ({ observation, handleClose }) => {
  return (
    <Card>
      <NotificationCardContainer>
        <h3 style={{ marginBottom: 8 }}>Observação do tipo de frete selecionado</h3>
        <p>{observation}</p>

        <Stack align="end" justify="end" m="16px 0 0 0">
          <NotificationButton variant="primary" onClick={handleClose}>Fechar</NotificationButton>
        </Stack>
      </NotificationCardContainer>
    </Card>
  )
}

export const NotificationOpenOrder = ({ information, handleClose }) => {
  return (
    <Card>
      <NotificationCardContainer>
        <h3 style={{ marginBottom: 8 }}>Informação Importante</h3>
        <p>{information}</p>

        <Stack align="end" justify="end" m="16px 0 0 0">
          <NotificationButton variant="primary" onClick={handleClose}>Fechar</NotificationButton>
        </Stack>
      </NotificationCardContainer>
    </Card>
  )
}