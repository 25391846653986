import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setPage } from 'application/store/promotions'

import * as S from './styles'

export const PaginationPromotion = () => {
  const items = useSelector((state) => state.promotions)
  const dispatch = useDispatch()

  const pagination = (count, page, limit) => {
    const totalPages = Math.ceil(count / limit)
    const pagination = []
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(i)
      }
    } else if (totalPages > 7 && page <= 4) {
      pagination.push(1, 2, 3, 4, 5, '...', totalPages)
    } else if (totalPages - page === 3 || totalPages - page < 3) {
      pagination.push(
        1,
        '...',
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      )
    } else {
      pagination.push(
        1,
        '...',
        page - 1,
        parseInt(page),
        parseInt(page) + 1,
        '...',
        totalPages
      )
    }
    return pagination
  }

  const handlePage = (page) => {
    dispatch(setPage(page))
  }

  return (
    <S.Wrapper>
      <S.PaginationBox>
        {pagination(items.itemTotalItems, items.page, 20).map(
          (element, index) =>
            element === '...' ? (
              <S.PaginationContinousItem key={index}>
                {element}
              </S.PaginationContinousItem>
            ) : items.page === element ? (
              <S.PaginationActive key={index}>{element}</S.PaginationActive>
            ) : (
              <S.PaginationItem key={index} onClick={() => handlePage(element)}>
                {element}
              </S.PaginationItem>
            )
        )}
      </S.PaginationBox>
    </S.Wrapper>
  )
}
