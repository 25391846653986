import { setAmount } from 'application/store/shopping-cart'
import { handleShoppingCartAmount } from 'application/usecases/handle-shopping-cart-amount'
import { createContext } from 'react'
import { useDispatch } from 'react-redux'

export const shoppingCartContext = createContext()

export const ShoppingCartProvider = ({ children }) => {
  const dispatch = useDispatch()

  const getAmount = async () => {
    const token = JSON.parse(localStorage.getItem('@barrosAuth'))
    const { data } = await handleShoppingCartAmount.handle({
      cCliente: Number(token?.clientsId)
    })

    const amount =
      data?.Pedido.find((item) => item?.dFases === 'Orçamento')?.vPedTotal ?? 0

    dispatch(setAmount(amount))
  }

  const resetAmount = () => {
    dispatch(setAmount(0))
  }

  return (
    <shoppingCartContext.Provider value={{ getAmount, resetAmount }}>
      {children}
    </shoppingCartContext.Provider>
  )
}
