import { createSlice } from '@reduxjs/toolkit'

export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    menuToggle: false
  },
  reducers: {
    toggle: (state) => {
      state.menuToggle = !state.menuToggle
    }
  }
})

export const { toggle } = templateSlice.actions

export default templateSlice.reducer
