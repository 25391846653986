import { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { CxtMenuMobile as ContextMenuMobile } from './ContextMenuMobile'
import { MenuMobileIcon } from './styles'

export const MenuMobile = () => {
  const [isOpen, setOpen] = useState(false)

  const handleChangeModal = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <MenuMobileIcon onClick={handleChangeModal}>
        <GiHamburgerMenu size={26} style={{ color: 'white' }} />
      </MenuMobileIcon>
      <ContextMenuMobile
        isOpen={isOpen}
        handleChangeModal={handleChangeModal}
      />
    </>
  )
}
