import styled from 'styled-components'

export const AnnouncementContainer = styled.div`
  background-color: var(--color-primary);
  padding: 15px 10px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  color: #fff;

  margin: 10px 0;

  & span {
    color: yellow;
    font-weight: bold;
  }
`
