import styled from 'styled-components'

export const SlideContainer = styled.div`
  direction: ltr;
  width: 100%;
  margin: auto;
`

export const Slide = styled.div`
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 400px;

    @media (max-width: 785px) {
      height: 340px;
    }
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
export const SlideIndicators = styled.div`
  cursor: pointer;
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: 1px solid white;
  margin: 5px;
  margin-top: -60px;
  transition: 0.3s;
  z-index: 1;
  & ul {
    margin: 0;
  }
  &.active {
    width: 8px;
    height: 8px;
    margin-top: -61px !important;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #fff;
  }
`
