import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTimes } from 'react-icons/fa'

import {
  setItemsList,
  setItemCategoriesList,
  setItemTotalItems,
  setItemListDone,
  setItemError,
  setItemCarModal,
  setCategoryId,
  setSubcategoryId,
  setFilter,
  setVeiculoId
} from 'application/store/promotions'
import { handleListCarBrand } from 'application/usecases/handle-list-car-brand'
import { handleListCarModel } from 'application/usecases/handle-list-car-model'
import { handleListPromotionItem } from 'application/usecases/handle-list-promotion-items'

import {
  Container,
  SpinnerPage,
  Modal,
  Card,
  Button,
  SelectLabel
} from 'view/components'

import * as S from './styles'
import { CardProductDefault } from 'view/components/card-product-default/CardProductDefault'
import { PaginationPromotion } from 'view/components/pagination-promotion'
import { Categories } from 'view/components/categories'
import { BsList } from 'react-icons/bs'
import { yearOptionsList } from 'view/helpers'
import { toast } from 'react-toastify'

export const ListPromotionItemPage = () => {
  const items = useSelector((state) => state.promotions)
  const conditions = useSelector((state) => state.conditions)
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const dispatch = useDispatch()

  const [showCategories, setShowCategories] = useState(false)
  const [category, setCategory] = useState('')
  const [subcategory, setSubcategory] = useState('')
  const [year, setYear] = useState('')
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState('')
  const [carsModel, setCarsModel] = useState([])

  const handleClickToOpenCategories = () => {
    setShowCategories(!showCategories)
  }

  const handleFilterCategories = (categoryId) => {
    dispatch(setCategoryId(categoryId))
  }

  const handleClearCategories = () => {
    setCategory('')
    dispatch(setCategoryId(0))
  }

  const handleFilterSubCategories = (subCategoryId) => {
    dispatch(setSubcategoryId(subCategoryId))
  }

  const handleClearSubcategories = () => {
    setSubcategory('')
    dispatch(setSubcategoryId(0))
  }

  const handleFilter = useCallback(() => {
    dispatch(setItemListDone(false))
    dispatch(setItemsList([]))
    dispatch(setItemCategoriesList([]))
    dispatch(setItemTotalItems(0))
    dispatch(setItemError(''))

    handleListPromotionItem
      .handle({
        customer: Number(token?.clientsId),
        filter: items.filter ? items.filter : 'filtro',
        pageSize: 20,
        pageNumber: items.page,
        promotionTypeId: items.promotionTypeId || ''
      })
      .then((res) => {
        dispatch(setItemsList(res.data.ListaItens))
        dispatch(setItemCategoriesList(res.data.ListaCategorias))
        dispatch(setItemTotalItems(res.data.TotalItens))
        dispatch(setItemListDone(true))
      })
      .catch((error) =>
        toast.error('Não foi possível listar os itens da promoção')
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.categoryId, items.subcategoryId, items.page, items.veiculoId])

  const handleClearFilter = () => {
    new Promise((resolve) => resolve(dispatch(setFilter('')))).then(() =>
      handleFilter()
    )
  }

  const handleBrands = useCallback(() => {
    handleListCarBrand.handle().then((res) => setBrands(res.data.RetMarcas))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year])

  const handleCarsModel = useCallback(() => {
    handleListCarModel
      .handle({
        Ano: year,
        Cmarca: brand
      })
      .then((res) => setCarsModel(res.data.RetVeiculos))
      .catch(() => toast.error('Não foi possível listar os produtos'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  useEffect(() => {
    handleBrands()
  }, [handleBrands])

  useEffect(() => {
    handleCarsModel()
  }, [handleCarsModel])

  const [carModel, setCarModel] = useState([])
  useEffect(() => {
    carsModel &&
      setCarModel(carsModel.filter((item) => item.cModelo === items.veiculoId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.veiculoId])

  const yearOptions = yearOptionsList()

  return (
    <>
      <Container>
        <div>
          <S.Title>
            <S.ButtonToMobileCategories onClick={handleClickToOpenCategories}>
              <BsList />
              Categorias
            </S.ButtonToMobileCategories>
            <p>{`Exibindo ${items.page * 20 - 20}-${items.page * 20} de ${
              items.itemTotalItems
            } produtos`}</p>

            <Categories
              show={showCategories}
              dispatch={dispatch}
              items={items}
              setItemCarModal={setItemCarModal}
              setCarsModel={setCarsModel}
              setCategory={setCategory}
              setSubcategory={setSubcategory}
              handleFilterCategories={handleFilterCategories}
              handleFilterSubCategories={handleFilterSubCategories}
            />
          </S.Title>

          <S.FiltersSelected>
            <p>Filtros Selecionados:</p>
            <S.FilterItems>
              {items.filter && (
                <S.FilterItem>
                  {items.filter}
                  <FaTimes onClick={handleClearFilter} />
                </S.FilterItem>
              )}
              {category && (
                <S.FilterItem>
                  {category}
                  <FaTimes onClick={handleClearCategories} />
                </S.FilterItem>
              )}
              {subcategory && (
                <S.FilterItem>
                  {subcategory}
                  <FaTimes onClick={handleClearSubcategories} />
                </S.FilterItem>
              )}
              {carModel.length > 0 && (
                <S.FilterItem>
                  {carModel[0].dMarca} / {carModel[0].ano}
                  <FaTimes onClick={() => dispatch(setVeiculoId(0))} />
                </S.FilterItem>
              )}
            </S.FilterItems>
          </S.FiltersSelected>

          <S.ContentPage>
            <S.Filter>
              {/* <Button
                color="--color-white"
                colorFont="--color-dark-grey"
                hoverColor="#C41104"
                onClick={() => {
                  dispatch(setItemCarModal(true))
                  setCarsModel([])
                }}
                block
              >
                Selecione seu veículo
              </Button> */}
              <h3>Categorias</h3>
              <S.FilterCheckboxes>
                {items.itemCategoriesList &&
                  items.itemCategoriesList.map((category) => {
                    return (
                      <>
                        <S.InputCategory key={category.ItensCategId}>
                          <S.Checkbox
                            id={category.ItensCategId}
                            type="checkbox"
                            value={category.ItensCategId}
                            onChange={() => {
                              setCategory(category.ItensCategDescri)
                              handleFilterCategories(category.ItensCategId)
                            }}
                          />
                          <label
                            htmlFor={category.ItensCategId}
                          >{`${category.ItensCategDescri}`}</label>
                        </S.InputCategory>

                        <S.SubCategoryList>
                          {category.ListaSubCategorias.map((item) => (
                            <div key={item.ItensSubCategId}>
                              <S.Checkbox
                                id={item.ItensSubCategId}
                                type="checkbox"
                                value={item.ItensSubCategDescri}
                                onChange={() => {
                                  setSubcategory(item.ItensSubCategDescri)
                                  handleFilterSubCategories(
                                    item.ItensSubCategId
                                  )
                                }}
                              />
                              <label
                                htmlFor={item.ItensSubCategId}
                              >{`${item.ItensSubCategDescri}`}</label>
                            </div>
                          ))}
                        </S.SubCategoryList>
                      </>
                    )
                  })}
              </S.FilterCheckboxes>
            </S.Filter>
            <div>
              {items.itemListDone ? (
                <S.ProductsContainer>
                  {items.itemsList?.map((item, index) => {
                    return (
                      <CardProductDefault
                        key={index}
                        product={item}
                        conditionPercentage={
                          conditions.list.length && conditions.selected
                            ? conditions.list.find((c) => {
                                return c.cPagtoId === conditions.selected
                              }).pPercentual
                            : 0
                        }
                      />
                    )
                  })}
                </S.ProductsContainer>
              ) : (
                <SpinnerPage />
              )}
              <PaginationPromotion />
            </div>
          </S.ContentPage>
        </div>
      </Container>

      <Modal
        visibleModal={items.itemCarModal}
        setVisibleModal={() => {
          dispatch(setItemCarModal(false))
          setYear('')
          setBrand('')
        }}
      >
        <Card>
          <S.ModalContent>
            <SelectLabel
              label="Ano"
              name="year"
              onChange={(e) => setYear(e.target.value)}
            >
              <option>Selecione...</option>
              {yearOptions.map((year, idx) => (
                <option key={idx} value={year.year}>
                  {year.year}
                </option>
              ))}
            </SelectLabel>
            {year && (
              <SelectLabel
                label="Marca"
                name="brandId"
                onChange={(e) => {
                  setBrand(e.target.value)
                }}
              >
                <option>Selecione...</option>
                {brands
                  .sort((a, b) => {
                    if (a.dMarca < b.dMarca) return -1
                    if (a.dMarca > b.dMarca) return 1
                    return 0
                  })
                  .map((brand) => (
                    <option key={brand.cMarca} value={brand.cMarca}>
                      {brand.dMarca}
                    </option>
                  ))}
              </SelectLabel>
            )}
            {brand && (
              <SelectLabel
                label="Modelo"
                name="carModelId"
                onChange={(e) => {
                  dispatch(setItemCarModal(false))
                  dispatch(setVeiculoId(e.target.value))
                }}
              >
                <option>Selecione...</option>
                {carsModel &&
                  carsModel
                    .sort((a, b) => {
                      if (a.dMarca < b.dMarca) return -1
                      if (a.dMarca > b.dMarca) return 1
                      return 0
                    })
                    .map((car) => (
                      <option key={car.cModelo} value={car.cModelo}>
                        {car.dMarca}
                      </option>
                    ))}
              </SelectLabel>
            )}
          </S.ModalContent>
        </Card>
      </Modal>
    </>
  )
}
