import 'react-slideshow-image/dist/styles.css'
import React from 'react'
import { Slide } from 'react-slideshow-image'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import * as S from './styles'
import { ArrowLeft, ArrowRight } from 'view/pages/home/styles'

export const Slider = ({ urlImages }) => {
  const properties = {
    infinite: true,
    duration: 5000,
    transitionDuration: 300,
    prevArrow: (
      <ArrowLeft notShouldCenter>
        <FaAngleLeft />
      </ArrowLeft>
    ),
    nextArrow: (
      <ArrowRight notShouldCenter>
        <FaAngleRight />
      </ArrowRight>
    ),
    indicators: () => <S.SlideIndicators />
  }

  return (
    <S.SlideContainer>
      <Slide {...properties}>
        {urlImages.map((image, index) => (
          <S.Slide key={index}>
            <div>
              <img src={image} alt="Banner" />
            </div>
          </S.Slide>
        ))}
      </Slide>
    </S.SlideContainer>
  )
}
