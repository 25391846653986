import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  0% {
    background-color: rgba(160, 160, 160, 0.2);
  }
  100% {
    background-color: rgba(110, 110, 110, 0.2);
  }
`

export const SkeletonContainer = styled.div`
  height: ${(props) => props.height ?? '100%'};
  width: ${(props) => props.width ?? '100%'};
  min-width: ${(props) => props.minWidth ?? 'auto'};
  animation: ${blink} .5s infinite alternate ease-in-out;
  border-radius: 5px;
  margin: ${(props) => props.margin ?? '0'};
`
