import { createSlice } from '@reduxjs/toolkit'

export const tituloSlice = createSlice({
  name: 'titulos',
  initialState: {
    tituloList: [],
    cedente: {}
  },
  reducers: {
    setTituloList: (state, action) => {
      state.tituloList = action.payload
    },
    setCedente: (state, action) => {
      state.cedente = action.payload
    }
  }
})

export const { setTituloList, setCedente } = tituloSlice.actions

export default tituloSlice.reducer
