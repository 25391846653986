import styled from 'styled-components'

export const Td = styled.td`
  display: flex;
  & button {
    margin-right: 4px;
  }
`

export const Status = styled.span`
  background-color: ${(props) => props.color};
  padding: 5px 10px;
  border-radius: 5px;
`
