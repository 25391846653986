import React from 'react'

import * as S from './styles'

export const SelectLabel = ({
  label,
  flexGrow,
  name,
  onChange,
  children,
  value,
  readOnly
}) => {
  return (
    <S.LabelSelect className={flexGrow ? flexGrow : null}>
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        disabled={readOnly}
      >
        {children}
      </select>
    </S.LabelSelect>
  )
}
