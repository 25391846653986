import ReactTooltip from 'react-tooltip'
import { PercentPromotionContainer } from './styles'

export function PercentPromotion({ children, color, tooltipText, tooltipDisable }) {
  return (
    <PercentPromotionContainer color={color} data-tip={tooltipText}>
      <ReactTooltip
        place="top"
        type="dark"
        effect="solid"
        disable={tooltipDisable}
      />
      {children}
    </PercentPromotionContainer>
  )
}
