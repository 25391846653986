import styled from 'styled-components'

const ErrorMessageContainer = styled.p`
  margin: 5px 0;
  color: red;
  font-size: 12px;
`

export const ErrorMessage = ({ children }) => {
  return <ErrorMessageContainer>{children}</ErrorMessageContainer>
}
