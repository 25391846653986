import styled from 'styled-components'

const StackContainer = styled.div`
  display: flex;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'center'};
  gap: ${({ gap }) => gap ?? 'center'};
  margin: ${({ m }) => m ?? '0'};
  padding: ${({ p }) => p ?? '0'};
`

export const Stack = (props) => <StackContainer {...props} />
