import { createSlice } from '@reduxjs/toolkit'

export const itemSlice = createSlice({
  name: 'items',
  initialState: {
    filterDescription: '',
    filterCode: '',

    filter: '',
    code: '',
    itemsList: [],

    category: '',
    categoryId: 0,
    subcategoryId: 0,
    itemCategoriesList: [],

    productBrands: [],

    veiculoId: 0,
    itemCarModal: false,

    itemListDone: false,
    itemError: '',

    page: 1,
    itemTotalItems: 0,

    promotionTypeId: '',
    promotionName: '',

    loading: false
  },
  reducers: {
    clearCategories: (state) => {
      state.category = ''
      state.categoryId = 0
      state.subcategoryId = 0
    },
    setCategory: (state, action) => {
      state.category = action.payload
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload
    },
    setSubcategoryId: (state, action) => {
      state.subcategoryId = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setCode: (state, action) => {
      state.code = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setVeiculoId: (state, action) => {
      state.veiculoId = action.payload
    },
    setItemsList: (state, action) => {
      state.itemsList = action.payload
    },
    setItemCategoriesList: (state, action) => {
      state.itemCategoriesList = action.payload
    },
    setItemTotalItems: (state, action) => {
      state.itemTotalItems = action.payload
    },
    setItemListDone: (state, action) => {
      state.itemListDone = action.payload
    },
    setItemError: (state, action) => {
      state.itemError = action.payload
    },
    setItemCarModal: (state, action) => {
      state.itemCarModal = action.payload
    },
    setFilterDescription: (state, action) => {
      state.filterDescription = action.payload
    },
    setFilterCode: (state, action) => {
      state.filterCode = action.payload
    },
    setItemPromotionTypeId: (state, action) => {
      state.promotionTypeId = action.payload
    },
    setItemPromotionName: (state, action) => {
      state.promotionName = action.payload
    },
    setProductBrands: (state, action) => {
      state.productBrands = action.payload
    },
    setProductBrandsForIndex: (state, action) => {
      const { i, checked } = action.payload
      state.productBrands[i].check = checked
      state.productBrands = [...state.productBrands]
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const {
  clearCategories,
  setCategory,
  setCategoryId,
  setSubcategoryId,
  setFilter,
  setCode,
  setPage,
  setVeiculoId,
  setItemsList,
  setItemCategoriesList,
  setItemTotalItems,
  setItemListDone,
  setItemError,
  setItemCarModal,
  setFilterDescription,
  setFilterCode,
  setItemPromotionTypeId,
  setItemPromotionName,
  setProductBrands,
  setProductBrandsForIndex,
  setLoading
} = itemSlice.actions

export default itemSlice.reducer
