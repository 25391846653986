import { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { handleShowOrder } from 'application/usecases/handle-show-order'
import { setOrder, setItemList, setRefresh } from 'application/store/orders'

import { Container, PageTitle, Spinner } from 'view/components'
import { toMoney } from 'view/helpers'

import { Item } from './item'
import * as S from './styles'
import { endRequest } from './services'
import { handleListConditions } from 'application/usecases/handle-list-conditions'
import { toast } from 'react-toastify'
import { ErrorMessage } from 'view/components/error-message'
import { MinValue, NotificationModal } from 'view/components/MinValue'
import { useMemo } from 'react'
import { handleDeleteAllOrders } from 'application/usecases/handle-delete-all-orders'
import { handleListFreighttypes } from 'application/usecases/handle-list-freight-types'
import { NotificationFreightType, NotificationOpenOrder } from './notifications'
import { handleStoreOrder } from 'application/usecases/handle-store-item'

export const ShowOrderPage = () => {
  const [deletingAllOrders, setDeletingAllOrders] = useState(false)
  const [loading, setLoading] = useState(true)
  const [informationOrderDialog, setInformationOrderDialog] = useState(false)
  const [freightTypes, setFreightTypes] = useState([])
  const [freightSelected, setFrengthSelected] = useState('')
  const [freightTypeObservation, setFreightTypeObservation] = useState('')
  const [conditions, setConditions] = useState([])
  const [conditionSelected, setConditionSelected] = useState('')
  const [conditionDialog, setConditionDialog] = useState(false)
  const [ending, setEnding] = useState(false)
  const [errors, setErrors] = useState([])
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const params = useParams()
  const navigate = useNavigate()

  const orders = useSelector((state) => state.orders)
  const dispatch = useDispatch()

  const [finalized, setFinalized] = useState(false)

  const fetchOrder = useCallback(() => {
    handleShowOrder
      .handle({ Cpedido: params.id })
      .then((res) => {
        dispatch(setRefresh(false))
        dispatch(setOrder(res.data.Pedido))
        dispatch(setItemList(res.data.Pedido[0].Itens))
        setFinalized(res.data.Pedido[0].dFases !== 'Orçamento')

        if (res.data.Pedido[0]?.Informacao) setInformationOrderDialog(true)
      })
      .catch(() => {
        toast.error('Não foi possível listar os pedidos')
      })
  }, [params.id, dispatch])

  const fetchConditions = async () => {
    const response = await handleListConditions.handle({
      cCliente: Number(token?.clientsId)
    })
    const data = response?.data
    if (data?.nroRegistros > 0)
      setConditions(data?.Condicao?.sort((a, b) => a - b))
  }

  const fetchFreightTypes = async () => {
    const { data } = await handleListFreighttypes.handle({
      ClientesId: Number(token.clientsId),
      ValorPedido: orders.order[0]?.vPedTotal
    })

    setFreightTypes(data ?? [])
  }

  const handleChangeCondition = (e) => {
    const value = e.target.value
    if (!value) return setConditionSelected('')

    const condit = JSON.parse(value)
    const total = Number(orders.order[0]?.vPedTotal)
    setErrors([])

    setConditionSelected(condit)

    if (Number(condit.vValorMin) >= total) setConditionDialog(true)
  }

  const handleChangeFreightType = (e) => {
    const value = e.target.value
    setFrengthSelected(value)

    const freightType = freightTypes.find((freight) => freight.Codigo === value)

    setFreightTypeObservation(freightType?.Observacao ?? '')
  }

  useEffect(() => {
    setFinalized(false)
    dispatch(setOrder([]))
    fetchOrder()
  }, [fetchOrder, dispatch])

  useEffect(() => {
    if (orders.refresh) {
      setFinalized(false)
      dispatch(setOrder([]))
      fetchOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders.refresh])

  useEffect(() => {
    setLoading(true)
    try {
      if (!conditions.length) {
        try {
          fetchConditions()
        } catch (error) {
          toast.error('Não foi possível listar as condições de pagamentos')
        }
      }

      if (!freightTypes.length && orders.order.length) {
        try {
          fetchFreightTypes()
        } catch (error) {
          toast.error('Não foi possível listar os tipos de frete')
        }
      }
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao listar as condições de pagamentos e os tipos de frete'
      )
    } finally {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions.length, freightTypes.length, orders.order])

  async function handleEndRequest() {
    setErrors([])

    if (!conditionSelected) {
      if (!errors.filter((e) => e?.id === 'condition').length) {
        return setErrors([
          ...errors,
          {
            id: 'notHasCondition',
            Description: 'Selecione uma Condição de Pagamento'
          }
        ])
      }

      return
    }

    if (!freightSelected) {
      if (!errors.filter((e) => e?.id === 'freight').length) {
        return setErrors([
          ...errors,
          {
            id: 'notHasFreight',
            Description: 'Selecione um Tipo de Frete'
          }
        ])
      }

      return
    }

    const quantityAndUnit = orders.itemList
      .map((item) => ({
        quantidade: item.vQuantidade,
        unitario: item.vUnitario
      }))
      .reduce((acc, cur) => ({
        quantidade: Number(acc.quantidade) + Number(cur.quantidade),
        unitario: Number(acc.unitario) + Number(cur.unitario)
      }))

    setEnding(true)

    try {
      for (const item of orders.itemList) {
        await handleStoreOrder.handle({
          cCliente: JSON.parse(localStorage.getItem('@barrosAuth'))?.clientsId,
          cItem: item.dItemCod,
          qtd: Number(item.vQuantidade),
          vUnit: (
            Number(item.vUnitario) +
            (Number(item.vUnitario) / 100) *
              (item?.bPromocao ? 0 : conditionSelected?.pPercentual ?? 0)
          ).toFixed(2),
          cCondicao: Number(conditionSelected.cPagtoId),
          cOperacao: 4
        })
      }

      await endRequest({
        Cpedido: params.id,
        cCondicao: Number(conditionSelected.cPagtoId),
        cTipoFrete: Number(freightSelected)
      }).then((res) => {
        if (res.data.Messages[0].Id) {
          if (res.data.Messages[0].Id !== '200') {
            setErrors(res.data.Messages)
            return res.data.Messages.map((message) =>
              toast.error(message.Description)
            )
          }
        }

        res.data.Messages.map((message) => toast.success(message.Description))
        navigate('/pedidos')
      })
    } catch (err) {
      toast.error(err.message)
    } finally {
      setEnding(false)
    }
  }

  const handleDeleteAll = async () => {
    setDeletingAllOrders(true)

    await handleDeleteAllOrders
      .handle({
        cPedido: orders.order[0].cPedido
      })
      .then((res) => {
        if (res.data.Messages[0].Id) {
          if (res.data.Messages[0].Id !== '200') {
            setErrors(res.data.Messages)
            return res.data.Messages.map((message) =>
              toast.error(message.Description)
            )
          }
        }

        res.data.Messages.map((message) => toast.success(message.Description))
        navigate('/pedidos')
      })
      .catch((err) => {
        err?.Messages?.map((message) => toast.error(message?.Description)) ??
          toast.error('Não foi possível deletar')
      })
      .finally(() => setDeletingAllOrders(false))
  }

  const totalAmount = useMemo(() => {
    return (
      orders?.itemList
        ?.map(
          (item) =>
            Number(item?.vTotItem) +
            (Number(item?.vTotItem) / 100) *
              (item?.bPromocao ? 0 : conditionSelected?.pPercentual ?? 0)
        )
        ?.reduce((acc, cur) => acc + cur, 0) ?? []
    )
  }, [conditionSelected?.pPercentual, orders])

  return (
    <>
      <NotificationModal show={conditionDialog}>
        <MinValue
          condition={conditionSelected ?? {}}
          onClose={() => setConditionDialog(false)}
        />
      </NotificationModal>

      <NotificationModal show={!!freightTypeObservation}>
        <NotificationFreightType
          handleClose={() => setFreightTypeObservation('')}
          observation={freightTypeObservation}
        />
      </NotificationModal>

      <NotificationModal show={informationOrderDialog}>
        <NotificationOpenOrder
          handleClose={() => setInformationOrderDialog(false)}
          information={orders?.Informacao}
        />
      </NotificationModal>

      <Container>
        <PageTitle>Pedido</PageTitle>

        {ending && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.2)'
            }}
          >
            <Spinner absolute size="60" />
          </div>
        )}

        <S.CartContent>
          <S.CartProducts>
            <S.CartProductsTable>
              <thead>
                <tr>
                  <th colSpan={2}>Qtde</th>
                  <th>Unitário</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              {!loading &&
                orders.itemList &&
                orders.itemList.map((item) => (
                  <Item
                    key={item.dIndice}
                    item={item}
                    orderId={params.id}
                    promotionId={conditionSelected.cPagtoId}
                    promotionPercentage={conditionSelected.pPercentual ?? 0}
                    finalized={finalized}
                    minValue={conditionSelected?.vValorMin ?? 0}
                  />
                ))}
            </S.CartProductsTable>
          </S.CartProducts>
          <S.CartTotal>
            {!loading && (
              <S.CartTotalContent>
                <S.CartTotalHeader>
                  <p>
                    <strong>
                      Orçamento #{orders.order.map((item) => item.cPedExterno)}
                    </strong>
                  </p>
                  <S.ButtonToDeleteOrder
                    disabled={deletingAllOrders}
                    onClick={handleDeleteAll}
                  >
                    {deletingAllOrders ? 'Apagando...' : 'Apagar pedido'}
                  </S.ButtonToDeleteOrder>
                </S.CartTotalHeader>
                <div>
                  <p>
                    <strong>Data abertura:</strong>
                  </p>
                  {orders.order.map((item) => (
                    <p key={item.cPedido}>
                      {dayjs(item.dtEmissao).format('DD/MM/YY')}
                    </p>
                  ))}
                </div>
                <hr />
                <div>
                  <p>
                    <strong>Total:</strong>
                  </p>
                  {orders.order.map((item) => (
                    <p key={item.cPedido}>{toMoney(totalAmount)} </p>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: 10,
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {!loading && (
                    <S.ConditionSelect
                      value={JSON.stringify(conditionSelected)}
                      onChange={handleChangeCondition}
                      disabled={finalized}
                    >
                      <option value="">
                        Selecione a Condição de Pagamento
                      </option>

                      {conditions.map((condition) => (
                        <option
                          key={condition.cPagtoId}
                          value={JSON.stringify(condition)}
                        >
                          {`${condition.dDescricao} - VLR. MIN.: $${Number(
                            condition.vValorMin
                          ).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2
                          })}`.toLowerCase()}
                        </option>
                      ))}
                    </S.ConditionSelect>
                  )}
                </div>

                <div
                  style={{
                    marginTop: 10,
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {!loading && (
                    <S.ConditionSelect
                      value={freightSelected}
                      onChange={handleChangeFreightType}
                      disabled={finalized}
                    >
                      <option value="">Selecione o tipo de frete</option>

                      {freightTypes.map((freightType) => (
                        <option
                          key={freightType.Codigo}
                          value={freightType.Codigo}
                        >
                          {freightType.Descricao}
                        </option>
                      ))}
                    </S.ConditionSelect>
                  )}
                </div>

                {errors.map((error, index) => (
                  <ErrorMessage key={index}>{error.Description}</ErrorMessage>
                ))}

                <div style={{ marginTop: 10 }}>
                  <S.CardButton
                    onClick={handleEndRequest}
                    background="gray"
                    color="gray"
                    type="button"
                    disabled={finalized}
                  >
                    {ending ? 'Finalizando pedido...' : 'Finalizar pedido'}
                  </S.CardButton>
                </div>
              </S.CartTotalContent>
            )}
          </S.CartTotal>
        </S.CartContent>
      </Container>
    </>
  )
}
