export const cnpjMask = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export const unmask = (value) =>
  value.replace(/\D+/g, '').replace(/([0-9])/, '$1')

export const toMoney = (value) =>
  Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

export const toNumber = (value) =>
  Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })

export const yearOptionsList = () => {
  const currentYear = new Date().getFullYear()
  const options = []
  for (let i = currentYear; i >= 1930; i--) {
    options.push({
      year: i
    })
  }
  return options
}
