import styled from 'styled-components'

export const ConditionContainer = styled.div`
  padding: 0 4px;
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1150px) {
    padding: 0 0 0 5px;
  }

  @media (max-width: 920px) {
    width: 100%;
    max-width: 100%;
    min-width: 300px;
    padding: 0;
    margin-top: 10px;
    justify-content: center;
  }
`

export const ConditionSelect = styled.select`
  font-size: 14px;
  padding: 8px;
  height: 34px;
  width: 300px;
  border-radius: 3px;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  @media (max-width: 920px) {
    width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }

  & option {
    color: black;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: #ccc;
  transition: 0.3s;
  margin: 0 10px;

  &:hover {
    color: var(--color-white);
    cursor: pointer;
  }
`

export const ContextMenu = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: white;
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 99;
  width: max-content;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);

  @media (max-width: 920px) {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 300px;
  }
`

export const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-black);
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
`

export const ConditionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-transform: lowercase;

  &:hover {
    text-decoration: underline;
  }
`

export const ConditionLabel = styled.p`
  margin-right: 4px;
  cursor: pointer;
`
export const ConditionBackground = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
`
