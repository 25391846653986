import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setSelected,
  setList,
  setSelectedDescription
} from 'application/store/conditions'
import {
  ConditionBackground,
  ConditionContainer,
  ConditionLabel,
  ConditionLabelContainer,
  ContextMenu,
  ContextMenuItem,
  MenuItem
} from './styles'
import { handleListConditions } from 'application/usecases/handle-list-conditions'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { GoChevronDown } from 'react-icons/go'
import { MinValue, NotificationModal } from '../MinValue'

export const Conditions = () => {
  const [isOpen, setOpen] = useState(false)
  const [conditionDialog, setConditionDialog] = useState(false)
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const conditions = useSelector((state) => state.conditions)
  const conditionSelec = conditions.list.find(
    (c) => c.cPagtoId === conditions.selected
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const conditionSelected = localStorage.getItem('@BarrosConditions')

    if (!conditions.list.length) {
      fetchConditions()
    }

    if (conditionSelected) {
      fetchConditions()
      dispatch(setSelected(conditionSelected))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions.list.length])

  useEffect(() => {
    if (
      !conditions.selectedDescription &&
      conditions.selected &&
      conditions.list.length
    ) {
      const description = conditions.list.find(
        (c) => c.cPagtoId === conditions.selected
      )?.dDescricao

      dispatch(setSelectedDescription(description ?? ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions.list.length])

  const fetchConditions = async () => {
    const response = await handleListConditions
      .handle({
        cCliente: Number(token?.clientsId)
      })
      .catch((err) => {
        console.error(err)
        toast.error('Não foi possível carregar as condições de pagamento')
      })

    const data = response?.data ?? {}
    if (data?.nroRegistros > 0)
      dispatch(setList(data?.Condicao?.sort((a, b) => a - b)))
  }

  const handleChangeCondition = (value, name) => {
    if (value) setConditionDialog(true)

    localStorage.setItem('@BarrosConditions', value)
    dispatch(setSelectedDescription(name))
    dispatch(setSelected(value))
    handleToggleModal()
  }

  const handleToggleModal = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <NotificationModal show={conditionDialog}>
        <MinValue
          condition={conditionSelec ?? {}}
          onClose={() => setConditionDialog(false)}
        />
      </NotificationModal>

      <ConditionContainer>
        <MenuItem
          style={{ position: 'relative', margin: '0 4px' }}
          onClick={handleToggleModal}
        >
          <ConditionLabelContainer>
            <ConditionLabel>
              {conditions.selectedDescription || 'Condição de Pagamento'}
            </ConditionLabel>
            <GoChevronDown />
          </ConditionLabelContainer>

          <ConditionBackground onClick={handleToggleModal} isOpen={isOpen} />
          <ContextMenu isOpen={isOpen}>
            <ContextMenuItem onClick={() => handleChangeCondition('', '')}>
              Condição de Pagamento
            </ContextMenuItem>

            {conditions.list.map((condition, index) => (
              <ContextMenuItem
                key={index}
                onClick={() =>
                  handleChangeCondition(
                    condition.cPagtoId,
                    condition.dDescricao
                  )
                }
              >
                {condition.dDescricao}
              </ContextMenuItem>
            ))}
          </ContextMenu>
        </MenuItem>
      </ConditionContainer>
    </>
  )
}
