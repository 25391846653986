export class ShoppingCartAmountService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(params) {
    return await this.httpClient.get('/pedidos/Lista', {
      params
    })
  }
}
