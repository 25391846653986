import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { handleRecoverPassword } from 'application/usecases/handle-recover-password'

import { InputLabel, Button } from 'view/components'

import { validation } from './validation'
import * as S from '../login/styles'
import { toast } from 'react-toastify'

export const RecoverPasswordPage = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues: { cnpj: '', user: '', password: '' },
    validationSchema: yup.object(validation(yup)),
    onSubmit: () => {
      setError('')

      handleRecoverPassword
        .handle({
          clientesEmail: formik.values.user
        })
        .then((data) => {
          toast.success(data?.Mensagem ?? 'Email enviado com sucesso')

          navigate('/')
        })
        .catch((error) => {
          setError(error?.response?.data?.Mensagem ?? 'Email não encontrado')
        })
    }
  })

  return (
    <S.Wrapper>
      <Link to="/">
        <img src="/logo.png" alt="logo" />
      </Link>
      <S.Card>
        <S.Login>
          <h1>Recuperar senha</h1>
          <p style={{ marginBottom: 16 }}>
            Informe o email cadastrado para receber uma nova senha temporária.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <InputLabel name="user" label="Email" formik={formik} />

            <div style={{ color: 'red', fontSize: 14 }}>{error}</div>

            <Button
              disabled={formik.isSubmitting}
              type="submit"
              color="--color-red"
            >
              {formik.isSubmitting ? 'Enviando...' : 'Enviar'}
            </Button>

            <Button
              disabled={formik.isSubmitting}
              color="--color-black"
              onClick={() => navigate('/login')}
            >
              Voltar
            </Button>
          </form>
        </S.Login>
      </S.Card>
    </S.Wrapper>
  )
}
