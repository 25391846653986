const { default: styled, keyframes } = require('styled-components')

export const BuyingAnimationContainer = styled.div`
  animation: ${(props) => (props.animationEnd ? faddingAnimation : 'none')} 0.2s;
  background-color: #eeeeee;
  margin: 0;
  border-radius: 5px;
  padding: 2px;
  height: 40px;
  width: 100%;
`

export const BuyingAnimationBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.background};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 100%;
  transition: width 0.3s;
`

const faddingAnimation = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0.1;
}
`
