import { useEffect, useState } from 'react'
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs'
import { BuyingAnimationBar, BuyingAnimationContainer } from './styles'

export const BuyingAnimation = ({
  requestStart,
  requestEnd,
  requestResult,
  clear
}) => {
  const [width, setWidth] = useState(0)
  const [animationEnd, setAnimationEnd] = useState(false)

  useEffect(() => {
    setWidth(0)
  }, [requestStart])

  useEffect(() => {
    let timeout1
    let timeout2

    if (requestStart) {
      timeout1 = setTimeout(() => setWidth(width + 2), 2)
      timeout2 = setTimeout(() => setWidth(width + 1), 4)

      if (width > 50) clearTimeout(timeout1)
      if (width > 85) clearTimeout(timeout2)
    }

    if (requestEnd) {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
      setWidth(100)

      setTimeout(() => {
        setAnimationEnd(true)
      }, 1900)

      setTimeout(() => {
        clear()
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStart, requestEnd, width])

  return (
    <BuyingAnimationContainer animationEnd={animationEnd}>
      <BuyingAnimationBar
        width={width}
        background={
          requestResult && requestEnd
            ? 'green'
            : !requestResult && requestEnd
            ? '#C41104'
            : 'green'
        }
      >
        {requestResult && requestEnd && <BsFillCheckCircleFill size={26} />}
        {!requestResult && requestEnd && <BsFillXCircleFill size={26} />}
      </BuyingAnimationBar>
    </BuyingAnimationContainer>
  )
}
