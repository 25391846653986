export class RecoverPasswordService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(params) {
    const res = await this.httpClient.get('v01/Usuario/enviaSenhaTemporaria', {
      params
    })

    return res.data
  }
}
