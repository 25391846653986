import styled from 'styled-components'

export const CartContent = styled.div`
  display: flex;
  @media (max-width: 1140px) {
    & {
      flex-direction: column;
    }
  }
`
export const CartProducts = styled.div`
  flex: 2;
  margin: 15px;

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }
`

export const CardButton = styled.button`
  cursor: pointer;
  background: ${({ background, contained }) =>
    contained ? background || 'transparent' : 'transparent'};
  color: ${({ color }) => (color ? color : '#333')};
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: ${({ contained, color }) =>
    contained ? 'none' : '1px solid ' + (color ? color : '#333')};
  transition: all 0.2s;
  margin: 0 !important;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(0.6);
  }

  &:disabled {
    color: #9b9b9b;
    outline: ${({ contained }) => (contained ? 'none' : '1px solid #e0e0e0')};
    cursor: default;
  }

  &:disabled:hover {
    filter: none;
  }
`

export const CartProductsTable = styled.table`
  background-color: var(--color-white);
  width: 100%;
  & thead tr {
    background-color: var(--color-medium-grey);
  }
  & thead tr th {
    padding: 10px;
    color: var(--color-white);
  }
  & tbody tr td {
    text-align: center;
    padding: 5px 10px;
  }
`
export const CartTotal = styled.div`
  flex: 1;
  margin-top: 16px;
`
export const CartTotalHeader = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CartTotalContent = styled.div`
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 15px;

  & div {
    font-size: 18px;
    display: flex;
  }
  & div p {
    margin: 4px 0;
  }
  & div p:nth-child(2) {
    margin-left: auto;
  }
  &button {
    width: 100%;
    margin-top: 36px;
  }

  & select {
    font-size: 16px;
    text-align: center;
  }
`
export const Description = styled.div`
  font-weight: bold;
  text-align: start;
`
export const Application = styled.div`
  font-size: 12px;
  text-align: start;
`
export const TdAmount = styled.td`
  & form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & form input {
    width: 80px;
    min-width: 50px;
  }
  @media (max-width: 540px) {
    & form input {
      width: 60px;
    }
  }
`
export const Divider = styled.td`
  border-bottom: 1px solid var(--color-medium-grey);
`

export const ConditionSelect = styled.select`
  font-size: 18 !important;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  outline: none;
`

export const ButtonToDeleteOrder = styled.button`
  background-color: var(--color-red);
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  &:disabled {
    cursor: default;
    background-color: var(--color-medium-grey);
  }

  &:disabled:hover {
    filter: none;
  }
`
