import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border: 1px solid var(--color-medium-grey);
  overflow-x: scroll;
  & th {
    padding: 12px;
    text-align: left;
    background-color: var(--color-light-grey);
  }
  & tr:nth-child(even) {
    background-color: var(--color-light-grey);
  }
  & td {
    padding: 8px;
  }

  @media (max-width: 540px) {
    & td {
      font-size: 12px;
    }
    & th {
      font-size: 12px;
    }
  }
`
