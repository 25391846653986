import { Routes, Route, HashRouter } from 'react-router-dom'
import { Layout } from 'layout/Layout'
import { PrivateRoute } from 'view/components'
import {
  LoginPage,
  HomePage,
  ListItemPage,
  ListOrderPage,
  ShowOrderPage,
  ShowItemPage,
  ListTituloPage,
  ListPromotionItemPage
} from 'view/pages'
import { ShoppingCartProvider } from 'layout/ShoppingCartContext'
import { RecoverPasswordPage } from 'view/pages/recover-password'

export const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/recuperar-senha" element={<RecoverPasswordPage />} />
        <Route element={<PrivateRoute />}>
          <Route
            element={
              <ShoppingCartProvider>
                <Layout />
              </ShoppingCartProvider>
            }
          >
            <Route path="/" element={<HomePage />} />
            <Route path="/pesquisa" element={<ListItemPage />} />
            <Route path="/promotion" element={<ListPromotionItemPage />} />
            <Route path="/pesquisa/:id" element={<ShowItemPage />} />
            <Route path="/pedidos" element={<ListOrderPage />} />
            <Route path="/pedidos/:id" element={<ShowOrderPage />} />
            <Route path="/titulos" element={<ListTituloPage />} />
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  )
}
