import { useEffect, useState, useRef } from 'react'
import './carrouselStyle.css'
import { useNavigate } from 'react-router-dom'
import { Slider } from 'view/components'
import {
  ArrowLeft,
  ArrowRight,
  CarouselContainer,
  Carrousel,
  Content,
  HomePageContainer,
  SeeAllProducts,
  Title
} from './styles'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { getBanners, getPromotionItens, getPromotionTypes } from './services'
import { CardProductDefault } from 'view/components/card-product-default/CardProductDefault'
import { useDispatch, useSelector } from 'react-redux'
import useResizeAware from 'react-resize-aware'
import { setItemPromotionName } from 'application/store/promotions'
import { Skeleton } from 'view/components/Skeleton'

export const HomePage = () => {
  const [itens, setItens] = useState([])
  const [urlImages, setUrlImages] = useState([])
  const [banners, setBanners] = useState([])
  const [resizeListener, sizes] = useResizeAware()

  useEffect(() => {
    if (sizes.width <= 768) {
      setUrlImages(banners.map((banner) => banner.EmpresaBannerImagemMobile))
    } else {
      setUrlImages(banners.map((banner) => banner.EmpresaBannerImagem))
    }
  }, [banners, sizes.width])

  useEffect(() => {
    getPromotionTypes()
      .then(async (res) => {
        const resItens = []

        for (const el of res.data) {
          await getPromotionItens({
            Pagesize: 30,
            Pagenumber: 1,
            Promotipoid: el.PromoTipoId
          })
            .then((res) => {
              if (res.data.ListaItens) {
                resItens.push({
                  order: el.PromoTipoOrdem,
                  promotionTypeId: el.PromoTipoId,
                  promotionBackground: el.PromoTipoCorFundo,
                  promotionColorFont: el.PromoTipoCorFonte,
                  description: el.PromoTipoDescri,
                  products: res.data.ListaItens
                })
              }
            })
            .catch((err) => console.error(err))
        }
        setItens(resItens.sort((a, b) => a.order - b.order))
      })
      .catch((err) => console.error(err.message ?? err))

    getBanners().then((res) => {
      setBanners(res.data.BannerRetorno)
    })
  }, [])

  return (
    <HomePageContainer>
      {resizeListener}
      {urlImages.length ? (
        <Slider urlImages={urlImages} />
      ) : (
        <Skeleton w={'100%'} h="400px" />
      )}
      <Content>
        {itens.length ? (
          itens.map((item, index) => {
            return <CardContainer item={item} key={index} />
          })
        ) : (
          <>
            <Skeleton w={'100%'} h="50px" />
            <div
              style={{ display: 'flex', overflow: 'hidden', marginTop: '10px' }}
            >
              {new Array(10).fill(0).map((_, index) => (
                <Skeleton
                  key={index}
                  minW="260px"
                  w="260px"
                  h="550px"
                  m="7px"
                />
              ))}
            </div>

            <Skeleton w={'100%'} h="50px" m="20px 0 0 0" />
            <div
              style={{
                display: 'flex',
                overflow: 'hidden',
                marginTop: '10px',
                marginBottom: '30px'
              }}
            >
              {new Array(10).fill(0).map((_, index) => (
                <Skeleton
                  key={index}
                  minW="260px"
                  w="260px"
                  h="550px"
                  m="7px"
                />
              ))}
            </div>
          </>
        )}
      </Content>
    </HomePageContainer>
  )
}

function CardContainer({ item }) {
  const [scrollIds, setScrollIds] = useState(
    item.products.map((product, index) => ({
      id: String(product.ItensId),
      active: index === 0 ? true : false
    }))
  )
  const [scrollLeftCorner, setScrollLeftCorner] = useState(true)
  const [scrollRightCorner, setScrollRightCorner] = useState(false)

  const conditions = useSelector((state) => state.conditions)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const carrouselRef = useRef()

  function handleClickToNavigate(promotionTypeId, promotionName) {
    dispatch(setItemPromotionName(promotionName))
    navigate(`/pesquisa?promocao=${promotionTypeId}`)
  }

  function handleClickArrowLeft() {
    const i = scrollIds.findIndex((s) => s.active)
    if (carrouselRef.current.scrollLeft <= 0) return setScrollLeftCorner(true)

    document
      .getElementById(scrollIds[i - 1].id)
      .scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' })

    scrollIds[i].active = false
    scrollIds[i - 1].active = true
    setScrollIds([...scrollIds])

    if (carrouselRef.current.scrollLeft <= 275) return setScrollLeftCorner(true)

    setScrollLeftCorner(false)
    setScrollRightCorner(false)
  }

  function handleClickArrowRight() {
    const i = scrollIds.findIndex((s) => s.active)
    if (
      carrouselRef.current.offsetWidth + carrouselRef.current.scrollLeft >=
      carrouselRef.current.scrollWidth
    )
      return setScrollRightCorner(true)

    document
      .getElementById(scrollIds[i + 1].id)
      .scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' })

    scrollIds[i].active = false
    scrollIds[i + 1].active = true
    setScrollIds([...scrollIds])

    if (
      carrouselRef.current.offsetWidth + carrouselRef.current.scrollLeft >=
      carrouselRef.current.scrollWidth - 275
    )
      return setScrollRightCorner(true)

    setScrollRightCorner(false)
    setScrollLeftCorner(false)
  }

  return (
    <span>
      <Title
        background={item.promotionBackground}
        color={item.promotionColorFont}
      >
        {item.description}

        <SeeAllProducts
          onClick={() => {
            handleClickToNavigate(item.promotionTypeId, item.description)
          }}
        >
          VER TUDO
        </SeeAllProducts>
      </Title>
      <CarouselContainer>
        {!scrollLeftCorner && (
          <ArrowLeft onClick={() => handleClickArrowLeft()}>
            <FaAngleLeft />
          </ArrowLeft>
        )}

        {!scrollRightCorner && (
          <ArrowRight onClick={() => handleClickArrowRight()}>
            <FaAngleRight />
          </ArrowRight>
        )}

        <Carrousel ref={carrouselRef}>
          {item.products.map((product, index) => {
            return (
              <CardProductDefault
                id={product.ItensId}
                key={index}
                product={product}
                conditionPercentage={
                  conditions.list.length && conditions.selected
                    ? conditions.list.find((c) => {
                        return c.cPagtoId === conditions.selected
                      })?.pPercentual ?? 0
                    : 0
                }
              />
            )
          })}
        </Carrousel>
      </CarouselContainer>
    </span>
  )
}
