import { useNavigate } from 'react-router-dom'
import { AnnouncementContainer } from './styles'

export function Announcement() {
  const navigate = useNavigate()

  const handleNavigate = () => {
    return navigate(`/pesquisa?pagina=1&categoria=0&subcategoria=0&descricao=&fornecedor=&veiculo=&promocao=&anuncio=true`)
  }

  return (
    <AnnouncementContainer onClick={handleNavigate}>
      Aproveite todas as <span>ofertas</span> da nosssa loja!
      <span> Clique aqui</span> e veja todos os produtos em{' '}
      <span>promoção.</span>
    </AnnouncementContainer>
  )
}
