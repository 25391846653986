import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const ToastContainerStyle = styled(ToastContainer)`
  .Toastify__toast-container {}
  .Toastify__toast {}
  .Toastify__toast--error {
    background-color: #f8d7da;
    color: #721c24;
  }
  .Toastify__toast--warning {
    background-color: #fff3cd;
    color: #856404;
  }
  .Toastify__toast--success {
    background-color: var(--color-primary);
    color: #fff3cd;
  }
  .Toastify__toast-body {}
  .Toastify__progress-bar {
    background-color: #155724;
  }
  .Toastify__close-button {
    display: none;
  }
  .Toastify__progress-bar {
    background-color: #fff;
  }
`