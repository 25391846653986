import { Card } from 'view/components'
import { Stack } from 'view/components/Stack'
import { NotificationButton, NotificationCardContainer } from './styles'

export const NotificationTitle = ({ observations, handleClose }) => {
  return (
    <Card>
      <NotificationCardContainer>
        <h3 style={{ marginBottom: 8 }}>Observação</h3>
        {observations.map((observation) => (
          <p key={observation}>{observation}</p>
        ))}

        <Stack align="end" justify="end" m="16px 0 0 0">
          <NotificationButton variant="primary" onClick={handleClose}>
            Fechar
          </NotificationButton>
        </Stack>
      </NotificationCardContainer>
    </Card>
  )
}
