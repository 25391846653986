export class ResetPasswordService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(data) {
    const user = localStorage.getItem('@barrosAuth')
    if (!user) throw new Error('Usuário não encontrado')

    const { clientsId } = JSON.parse(user)

    const res = await this.httpClient.post('v01/Usuario/alterarSenha', {
      ...data,
      clientsId
    })

    return res.data
  }
}
