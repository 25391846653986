export class DescriptionService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async category(params) {
    return await this.httpClient.get('/Categoria/Categoria', { params })
  }

  async subcategory(params) {
    return await this.httpClient.get('/Categoria/SubCategoria', { params })
  }

  async brand(params) {
    return await this.httpClient.get('/Itens/Modelos', { params })
  }

  async veiculo(params) {
    return await this.httpClient.get('/veiculos/Veiculo', { params })
  }
}
