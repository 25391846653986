import styled from 'styled-components'

export const Message = styled.div`
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 10;
  width: 100%;
  max-width: 500px;
  &.alert-blue,
  &.alert-red,
  &.alert-green,
  &.alert-orange {
    border-radius: 8px;
    padding: 12px 16px 12px 24px;
    margin-bottom: 16px;
  }
  &.alert-red {
    color: #842029;
    border: 1px solid #842029;
    background-color: #f8d7da;
  }
  &.alert-green {
    color: #0f5132;
    border: 1px solid #0f5132;
    background-color: #d1e7dd;
  }
  &.alert-blue {
    color: #084298;
    border: 1px solid #084298;
    background-color: #cfe2ff;
  }
  &.alert-orange {
    color: #664d03;
    border: 1px solid #664d03;
    background-color: #fff3cd;
  }
`