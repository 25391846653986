import styled from 'styled-components'

export const FilterContainer = styled.div`
  display: none;
  background-color: #fff;
  position: absolute;
  height: auto;
  max-height: 400px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
  top: 50px;

  & h3 {
    font-weight: 600;
    margin: 10px 0 5px 0;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`

export const FilterCheckboxes = styled.div`
  max-height: calc(100% - 85px);
  width: 100%;
  border-top: 1px solid var(--color-medium-grey);
`

export const SubCategoryList = styled.div`
  display: flex;
  flex-direction: column;
  & div {
    width: 100%;
    padding-left: 24px;
    display: flex;
    align-items: flex-start;
  }
`

export const InputCategory = styled.div`
  & div {
    width: 100%;
    padding: 6px 0px;
    font-size: 12px;
    cursor: pointer;
  }
`

export const Checkbox = styled.input`
  margin-right: 8px;
`

export const FilterCheckboxesContainer = styled.div`
  overflow-y: scroll;
  scrollbar-color: var(--color-medium-grey);
  scrollbar-width: thin;
  height: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
