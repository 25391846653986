import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Carousel } from 'react-responsive-carousel'

import * as S from './styles'

import { handleStoreOrder } from 'application/usecases/handle-store-item'

import { Spinner } from 'view/components'
import { toMoney } from 'view/helpers'

import Validation from './validation'
import { useState } from 'react'
import { BuyingAnimation } from 'view/components/buying-animation/BuyingAnimation'
import { ErrorMessage } from 'view/components/error-message'
import { useShoppingCart } from 'hooks/useShoppingCart'
import { handleQuantityItem } from 'application/usecases/handle-quantity-item'

export const Details = ({
  item,
  product,
  conditionPercentage,
  conditionId
}) => {
  const [seeAllApplication, setSeeAllApplication] = useState(false)
  const [requestStart, setRequestStart] = useState(false)
  const [requestEnd, setRequestEnd] = useState(false)
  const [requestResult, setRequestResult] = useState(false)
  const [errMessage, setErrorMessage] = useState('')
  const { getAmount } = useShoppingCart()
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))

  const handleClearAnimationBar = () => {
    setRequestStart(false)
  }

  const formik = useFormik({
    initialValues: { amount: 1 },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: async () => {
      setErrorMessage('')

      const quantity = await handleQuantityItem.handle({
        Itemscod: product.ItensCod,
        Itensid: product.ItensId,
        Clientesid: Number(token?.clientsId)
      })

      if (quantity.data?.ItensSaldo <= 0)
        return setErrorMessage('Item indisponível')

      if (quantity.data?.ItensSaldo < formik.values.amount)
        return setErrorMessage(
          `Saldo insuficiente, disponível ${quantity.data?.ItensSaldo}`
        )

      setRequestStart(true)
      setRequestEnd(false)

      handleStoreOrder
        .handle({
          cCliente: Number(token?.clientsId),
          cItem: item.Codigo,
          qtd: formik.values.amount,
          vUnit: Number(item.Preco),
          cCondicao: Number(conditionId),
          cResp: 1219,
          // cFase: 21,
          // cForPagto: 2,
          // cConPagto: 1932,
          cOperacao: 4
        })
        .then((res) => {
          try {
            if (res.data?.Id) {
              if (Number(res.data?.Id) !== 200) {
                throw new Error(
                  res.data[0].Description ??
                    'Não foi possível adicionar o produto'
                )
              }
            }

            if (res.data?.Messages && res.data?.Messages.length) {
              res.data?.Messages.forEach((message) => {
                if (Number(message.Id) !== 200) {
                  throw new Error(
                    message.Description ??
                      'Não foi possível adicionar o produto'
                  )
                }
              })
            }

            setErrorMessage('')
            setRequestResult(true)
            toast.success('Produto adicionado com sucesso!')
          } catch (err) {
            setRequestResult(false)
            setErrorMessage(String(err))
          }
        })
        .catch(() => {
          toast.error('Não foi possível adicionar o produto')
        })
        .finally(() => {
          try {
            getAmount()
          } catch (err) {
            console.error(err)
          }

          setRequestEnd(true)
        })
    }
  })

  return (
    <>
      <S.Container>
        <S.Carousel>
          <Carousel autoPlay showStatus={false}>
            {item.ItemImagens &&
              item.ItemImagens.map((image, index) => (
                <S.Image key={index}>
                  <img src={image.URL} alt={image.ItensImagemDescri} />
                </S.Image>
              ))}
          </Carousel>
          {!item.ItemImagens && (
            <S.Image>
              <Spinner size={60} absolute />
              <img src="/no-image.jpg" alt="barros auto peças" />
            </S.Image>
          )}
        </S.Carousel>

        <S.BuyBox>
          <h1>{item.DescricaoB2B}</h1>
          <S.Info>
            <p>
              <strong>Código: </strong>
              {item.Codigo}
            </p>
            <p>
              <strong>Descrição: </strong>
              {item.Descricao}
            </p>
            <p>
              <strong>Categoria: </strong>
              {item.Categoria}
            </p>
            <p>
              <strong>Fornecedor: </strong>
              {item.ItensCodFornec}
            </p>
            <p>
              <strong>Marca: </strong>
              {item.ItensMarca}
            </p>
            <p>
              <strong>Aplicação: </strong>
              {seeAllApplication
                ? item.Aplicacao
                : item.Aplicacao
                ? item.Aplicacao.slice(0, 250)
                : ''}
              <br />
              <S.SeeMore
                alt="Ver mais características"
                aria-label="Ver mais características"
                onClick={() => setSeeAllApplication(!seeAllApplication)}
              >
                {seeAllApplication ? 'Ver menos detalhes' : 'Ver mais detalhes'}
              </S.SeeMore>
            </p>
            <p>
              <strong>Especificação Técnica: </strong>
              {item.EspTecnica}
            </p>
          </S.Info>
          <S.Form onSubmit={formik.handleSubmit}>
            <S.ProductCardPrice>
              {!Number(product.Preco) ? (
                <h2>Sob-Consulta</h2>
              ) : (
                <S.CardPrice>
                  {toMoney(
                    product.ItensPromocao
                      ? product.Preco
                      : Number(product.Preco ?? 0) +
                          (Number(product.Preco ?? 0) / 100) *
                            conditionPercentage
                  )}
                </S.CardPrice>
              )}
            </S.ProductCardPrice>

            

            {errMessage && <ErrorMessage>{errMessage}</ErrorMessage>}

            <S.CardConfirmBuy>
              {!requestStart && (
                <>
                  <span>
                    <S.CardInput
                      type="number"
                      name="amount"
                      readOnly={false}
                      formik={formik}
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      min={1}
                    />
                  </span>
                  <S.CardButton type="submit" background="gray" color="gray">
                    Adicionar
                  </S.CardButton>
                </>
              )}
              {requestStart && (
                <BuyingAnimation
                  requestStart={requestStart}
                  requestEnd={requestEnd}
                  requestResult={requestResult}
                  clear={handleClearAnimationBar}
                />
              )}
            </S.CardConfirmBuy>
          </S.Form>
        </S.BuyBox>
      </S.Container>
    </>
  )
}
