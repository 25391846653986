import React from 'react'

import * as S from './styles'

export const Input = ({ type, name, readOnly, formik, disabled }) => {
  return (
    <S.Input
      type={!type ? 'text' : type}
      name={name}
      readOnly={readOnly}
      min={type === 'number' ? 0 : ''}
      value={formik.values[name] ?? 1}
      onChange={formik.handleChange}
      disabled={disabled}
    />
  )
}
