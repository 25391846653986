import { createSlice } from '@reduxjs/toolkit'

export const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    list: []
  },
  reducers: {
    setBrandsList: (state, action) => {
      state.list = action.payload
    }
  }
})

export const { setBrandsList } = brandsSlice.actions

export default brandsSlice.reducer
