import * as Yup from 'yup'
import Validation from '../item-card/validation'
import { useFormik } from 'formik'
import { toMoney } from 'view/helpers'
import { toast } from 'react-toastify'
import { MdHelp } from 'react-icons/md'
import {
  CardButton,
  CardConfirmBuy,
  CardContainer,
  CardDetails,
  CardImage,
  CardInfo,
  CardInput,
  CardBetween,
  CardTitle
} from './styles'
import { PercentPromotion } from '../percent-promotion/PercentPromotion'
import { handleStoreOrder } from 'application/usecases/handle-store-item'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { BuyingAnimation } from '../buying-animation/BuyingAnimation'
import { ErrorMessage } from '../error-message'
import { useShoppingCart } from 'hooks/useShoppingCart'
import { handleQuantityItem } from 'application/usecases/handle-quantity-item'

export function CardProductDefault({
  product,
  conditionPercentage,
  minValue,
  ...others
}) {
  const [requestStart, setRequestStart] = useState(false)
  const [requestEnd, setRequestEnd] = useState(false)
  const [requestResult, setRequestResult] = useState(false)
  const [errMessage, setErrorMessage] = useState('')
  const conditions = useSelector((state) => state.conditions)
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const { getAmount } = useShoppingCart()

  const formik = useFormik({
    initialValues: { amount: 1 },
    validationSchema: Yup.object(Validation(Yup)),
    handleChange: (e) => {
      formik.setFieldValue('amount', e.target.value)
    },
    onSubmit: async () => {
      const quantity = await handleQuantityItem.handle({
        Itemscod: product.ItensCod,
        Itensid: product.ItensId,
        Clientesid: Number(token?.clientsId)
      })

      if (quantity.data?.ItensSaldo <= 0)
        return setErrorMessage('Item indisponível')

      if (quantity.data?.ItensSaldo < formik.values.amount)
        return setErrorMessage(
          `Saldo insuficiente, disponível ${quantity.data?.ItensSaldo}`
        )

      setRequestStart(true)
      setRequestEnd(false)

      await handleStoreOrder
        .handle({
          cCliente: Number(token?.clientsId),
          cItem: product.ItensCod,
          qtd: formik.values.amount ?? 1,
          vUnit: Number(product.Preco),
          cCondicao: Number(conditions.selected),
          cResp: 1219,
          // cFase: 21,
          // cForPagto: 2,
          // cConPagto: 1932,
          cOperacao: 4
        })
        .then(async (res) => {
          try {
            if (res.data?.Id) {
              if (Number(res.data?.Id) !== 200) {
                throw new Error(
                  res.data[0].Description ??
                    'Não foi possível adicionar o produto'
                )
              }
            }

            if (res.data?.Messages && res.data?.Messages.length) {
              res.data?.Messages.forEach((message) => {
                if (Number(message.Id) !== 200) {
                  throw new Error(
                    message.Description ??
                      'Não foi possível adicionar o produto'
                  )
                }
              })
            }

            try {
              getAmount()
            } catch (err) {
              console.error(err)
            }

            setErrorMessage('')
            setRequestResult(true)
            toast.success('Produto adicionado com sucesso!')
          } catch (err) {
            setRequestResult(false)
            setErrorMessage(String(err))
          }
        })
        .catch(() => toast.error('Não foi possível adicionar o produto'))
        .finally(() => {
          setRequestEnd(true)
        })
    }
  })

  const statusTypeEnum = {
    1: { title: 'Liquidação', color: '#ffc107' },
    2: { title: 'Promoção', color: 'green' },
    3: { title: 'Lançamento', color: '#4b99ff' }
  }

  const checkPromotionMessage =
    product.ItensPromoMsg && product.ItensPromoMsg !== ' ' ? false : true

  const handleClickToDetails = () => {
    Cookies.set('@barrosProduct', JSON.stringify(product))

    navigate(`/pesquisa/${product.ItensId}`)
  }

  const handleClearAnimationBar = () => {
    setRequestStart(false)
  }

  return (
    <CardContainer onSubmit={formik.handleSubmit} {...others}>
      <CardImage
        unavailable={product.ItensSaldo <= 0}
        onClick={handleClickToDetails}
      >
        <img src={product?.URLImagem} alt={'Produto'} />
      </CardImage>
      <CardDetails>
        <div>
          <CardTitle onClick={handleClickToDetails}>
            {product?.ItensDescri ?? 'Sem descrição'}
          </CardTitle>
          <CardInfo>
            <strong>Fornecedor: </strong>
            {product?.ItensCodFornec ?? 'Não informado'}
          </CardInfo>
          <CardInfo>
            <strong>Marca: </strong>
            {product?.ItensModelo ?? 'Não informado'}
          </CardInfo>
          <CardInfo>
            <strong>Aplicação: </strong>
            {product?.AplicacaoResumida
              ? product?.AplicacaoResumida.substr(0, 80)
              : ''}{' '}
            {product?.AplicacaoResumida
              ? product?.AplicacaoResumida.length >= 80 && '...'
              : ''}
          </CardInfo>
        </div>

        <div>
          <CardBetween>
            {!Number(product.Preco) ? (
              <h4>Sob-Consulta</h4>
            ) : (
              <h1>
                {String(
                  toMoney(
                    product?.ItensPromocao
                      ? String(product.Preco)
                      : Number(product.Preco) +
                          (Number(product.Preco) / 100) *
                            (conditionPercentage ?? 0)
                  )
                )}
              </h1>
            )}

            {product.ItensPromocao && (
              <PercentPromotion
                color={
                  statusTypeEnum[product?.ItensPromoTipo ?? '0']?.color ??
                  'gray'
                }
                tooltipText={
                  checkPromotionMessage
                    ? statusTypeEnum[product?.ItensPromoTipo ?? '0']?.title ??
                      ''
                    : product?.ItensPromoMsg ?? ''
                }
                tooltipDisable={checkPromotionMessage}
              >
                {statusTypeEnum[product?.ItensPromoTipo ?? '']?.title ?? ''}
                {product?.ItensPromoMsg && product?.ItensPromoMsg !== ' ' && (
                  <MdHelp />
                )}
              </PercentPromotion>
            )}
          </CardBetween>
          <CardBetween>
            <CardInfo
              color={Number(product?.ItensSaldo ?? 0) > 0 ? 'green' : 'black'}
            >
              <strong>
                {Number(product?.ItensSaldo ?? 0) > 0 && 'Disponível'}
              </strong>
            </CardInfo>
          </CardBetween>

          <ErrorMessage>{errMessage}</ErrorMessage>

          <CardConfirmBuy>
            {!requestStart && (
              <>
                <span>
                  <CardInput
                    type="number"
                    name="amount"
                    readOnly={false}
                    formik={formik}
                    value={formik.values.amount}
                    min={1}
                    onChange={formik.handleChange}
                  />
                </span>
                <CardButton type="submit" background="gray" color="gray">
                  Adicionar
                </CardButton>
              </>
            )}
            {requestStart && (
              <BuyingAnimation
                requestStart={requestStart}
                requestEnd={requestEnd}
                requestResult={requestResult}
                clear={handleClearAnimationBar}
              />
            )}
          </CardConfirmBuy>

          <CardButton
            onClick={handleClickToDetails}
            background="gray"
            color="gray"
            type="button"
          >
            Detalhes
          </CardButton>
        </div>
      </CardDetails>
    </CardContainer>
  )
}
