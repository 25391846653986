import axios from 'axios'

const api = axios.create({ baseURL: process.env.REACT_APP_URL_API })

export const setInterceptors = (authToken) => {
  api.interceptors.request.use(async (config) => {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `${authToken}`
      config.headers['Cache-Control'] = 'no-cache'
      config.headers.Pragma = 'no-cache'
      config.headers.Expires = '0'
    }
    return config
  })
}

export const apiLogin = axios.create({
  baseURL: process.env.REACT_APP_URL_API_LOGIN
})

export { api }
