import React from 'react'

import * as S from './styles'

export const InputLabel = ({ type, name, label, readOnly, formik, mask }) => {
  return (
    <>
      <S.Wrapper>
        <S.Label>
          <label htmlFor={name}>{label}</label>
          <div>
            {formik.touched[name] && formik.errors[name]
              ? formik.errors[name]
              : null}
          </div>
        </S.Label>
        <input
          type={!type ? 'text' : type}
          id={name}
          name={name}
          readOnly={readOnly}
          value={mask ? mask(formik.values[name]) : formik.values[name]}
          onChange={
            mask
              ? (e) => formik.setFieldValue(name, mask(e.target.value))
              : formik.handleChange
          }
        />
      </S.Wrapper>
    </>
  )
}
