import {
  ContainerMenuMobile,
  ContextMenuMobile,
  Hello,
  MenuMobileFooter,
  MenuMobileItem,
  MenuMobileNav,
  Title
} from './styles'
import { BsPersonCircle } from 'react-icons/bs'
import { MdOutlineClose } from 'react-icons/md'
import { BiExit } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'
import { useShoppingCart } from 'hooks/useShoppingCart'
import { cnpjMask } from 'view/helpers'
import { HiOutlineKey } from 'react-icons/hi'
import { ChangePassword } from './ChangePassword'
import { useState } from 'react'

export const CxtMenuMobile = ({ isOpen, handleChangeModal }) => {
  const [isOpenModal, setOpenModal] = useState(false)
  const { resetAmount } = useShoppingCart()
  const navigate = useNavigate()
  const cnpj = JSON.parse(localStorage.getItem('@barrosAuth'))?.cnpj ?? ''

  const handleResetPassword = () => {
    setOpenModal(!isOpenModal)
    handleChangeModal()
  }

  const logout = () => {
    resetAmount()
    localStorage.removeItem('@barrosAuth')
    navigate('/login')
  }

  return (
    <>
      <ContextMenuMobile isOpen={isOpen}>
        <ContainerMenuMobile isOpen={isOpen}>
          <Hello>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <BsPersonCircle size={32} /> Olá!
            </div>
            <span>{cnpjMask(cnpj)}</span>
          </Hello>
          <MenuMobileNav>
            <Title>Destaques</Title>
            <MenuMobileItem onClick={handleChangeModal}>
              <Link to="/">Página inicial</Link>
            </MenuMobileItem>
            <MenuMobileItem onClick={handleChangeModal}>
              <Link to="/pesquisa">Promoções</Link>
            </MenuMobileItem>
            <MenuMobileItem onClick={handleChangeModal}>
              <Link to="/titulos">Boletos</Link>
            </MenuMobileItem>
            <MenuMobileItem onClick={handleChangeModal}>
              <Link to="/pedidos">Pedidos</Link>
            </MenuMobileItem>
          </MenuMobileNav>

          <MenuMobileFooter>
            <Title>Configurações</Title>
            <MenuMobileItem onClick={handleResetPassword}>
              <HiOutlineKey /> Alterar senha
            </MenuMobileItem>
            <MenuMobileItem onClick={logout}>
              <Link to="/">
                <BiExit /> Sair
              </Link>
            </MenuMobileItem>
          </MenuMobileFooter>
        </ContainerMenuMobile>
        <MdOutlineClose size={35} onClick={handleChangeModal} />
      </ContextMenuMobile>

      <ChangePassword isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
    </>
  )
}
