export class ListCarBrandService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(controllerSignal) {
    return await this.httpClient.get('/veiculos/listaMarcas', {
      signal: controllerSignal?.signal
    })
  }
}
