import { useFormik } from 'formik'
import { Button, InputLabel, Modal } from 'view/components'
import { useState } from 'react'
import { handleResetPassword } from 'application/usecases/handle-reset-password copy'
import { validation } from './validation'
import * as yup from 'yup'
import { toast } from 'react-toastify'

export const ChangePassword = ({ isOpenModal, setOpenModal }) => {
  const [error, setError] = useState('')
  const formik = useFormik({
    initialValues: { user: '', password: '' },
    validationSchema: yup.object(validation(yup)),
    onSubmit: () =>
      handleResetPassword
        .handle({
          clientesEmail: formik.values.user,
          senha: formik.values.password
        })
        .then((data) => {
          if (!data) throw new Error()

          toast.success(data?.Mensagem ?? 'Senha alterada com sucesso')
        })
        .catch((error) => {
          setError(error?.response?.data?.Mensagem ?? 'Email não encontrado')
        })
  })

  return (
    <>
      <Modal
        visibleModal={isOpenModal}
        setVisibleModal={() => setOpenModal(false)}
      >
        <div
          style={{
            borderRadius: 4,
            padding: 30,
            minWidth: 300,
            background: '#fff',
            zIndex: 999
          }}
        >
          <h1 style={{ marginBottom: 16 }}>Alterar senha</h1>
          <form onSubmit={formik.handleSubmit}>
            <InputLabel name="user" label="Email" formik={formik} />
            <InputLabel
              type="password"
              name="password"
              label="Nova senha"
              formik={formik}
            />

            <div style={{ color: 'red', fontSize: 14, marginBottom: 16 }}>
              {error}
            </div>

            <Button
              disabled={formik.isSubmitting}
              type="submit"
              color="--color-red"
              style={{ width: '100%' }}
            >
              {formik.isSubmitting ? 'Enviando...' : 'Enviar'}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  )
}
