import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Header = styled.header`
  background-color: var(--color-topbar-background);
  height: 70px;
  padding: 0 8px;

  @media (max-width: 1200px) {
    & {
      padding: 8px;
      height: auto;
    }
  }

  @media (max-width: 920px) {
    & {
      min-height: 237px;
      height: 237px;
    }
  }
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  & a {
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    & {
      height: auto;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
`
export const TopbarSearchBox = styled.div`
  flex: 1;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  padding: 0;

  @media (max-width: 920px) {
    & {
      min-width: fit-content;
    }
  }
`
export const MenuMobileIcon = styled.div`
  display: none;
  margin: 0 10px 0 0;
  cursor: pointer;
  transition: all ease 0.3s;
  @media (max-width: 1345px) {
    & {
      display: block;
    }
  }
`
export const TopbarLogo = styled(Link)`
  margin: 0 10px;

  @media (max-width: 920px) {
    display: flex;
    justify-content: center;
    width: calc(100% - 100px);
  }
`
export const SearchBox = styled.form`
  width: 100%;
  max-width: 600px;
  margin: 0;
  display: flex;
  align-items: center;

  & input {
    padding: 8px;
    font-size: 15px;
    border-radius: 3px;
    background-color: white;
    border-radius: 3px;
    outline: none;
    border: none;
    margin-right: 5px;

    &:focus {
      outline: none;
    }

    @media (max-width: 920px) {
      max-width: 100%;
      min-width: 100%;
      margin: 0;
    }
  }

  & #search-button {
    min-width: 34px;
    width: 34px;

    @media (max-width: 920px) {
      min-width: auto;
      width: 100%;
    }
  }

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: #ccc;
`

export const Menu = styled.div`
  display: flex;
  margin: 0 10px;

  @media (max-width: 1345px) {
    display: none;
  }
`
export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: #ccc;
  transition: 0.3s;
  margin: 0 10px;

  & svg {
    font-size: 20px;
    margin: 0 0px;
  }
  &:hover {
    color: var(--color-white);
    cursor: pointer;
  }
`
export const MenuItemLabel = styled.div`
  font-size: 15px;
  margin-left: 5px;
`
export const Submenu = styled.div`
  display: inline;
  background-color: var(--color-white);
  position: absolute;
  top: 48px;
  right: 0;
  border: 1px solid var(--color-medium-grey);
  cursor: auto;
  z-index: 2;
  & button {
    width: 100%;
  }
  @media (max-width: 920px) {
    & {
      top: 44px;
    }
  }
`
export const SubmenuButton = styled.div`
  margin: 30px;
`

export const TopbarMenuItemLabelsMobile = styled.div`
  display: none;
  @media (max-width: 600px) {
    & {
      display: flex;
      flex-direction: column;
    }
  }
  & p {
    margin: 0;
    font-size: 12px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 34px;
  cursor: pointer;
  background-color: var(--color-primary);
  border-radius: 3px;
  transition: 0.3s;
  border: none;
  outline: none;
  color: var(--color-white);
  &:hover {
  }

  &:disabled {
    background-color: #777777;
  }
`
export const ContextMenuMobile = styled.div`
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  display: flex;
  transition: 0.3s;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  height: 100%;
  width: 100%;
  z-index: 999;
  & svg {
    cursor: pointer;
  }
`

export const ContainerMenuMobile = styled.div`
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(-280%)'};
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  color: var(--color-black);
  height: 100%;
  width: 80%;
`

export const Hello = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-topbar-background);
  color: var(--color-white);
  font-size: 22px;
  font-weight: bold;
  padding: 20px 25px;
  & svg {
    cursor: default;
    margin-right: 10px;
  }
`

export const MenuMobileNav = styled.div`
  height: 100%;
`

export const MenuMobileFooter = styled.div`
  border-top: 1px solid #ccc;
`

export const MenuMobileItem = styled.div`
  font-size: 16px;
  padding: 15px 25px;
  transition: 0.3s;

  &:hover {
    background-color: #e6e6e6;
  }

  & a {
    color: var(--color-black);
    display: flex;
    align-items: center;

    & svg {
      margin-right: 10px;
    }
  }
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  padding: 15px 25px;
`

export const ContextMenu = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: white;
  position: absolute;
  padding: 10px;
  top: 30px;
  right: 0px;
  z-index: 99;
  width: 180px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
`

export const ContextMenuItem = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-black);
  padding: 10px 5px;
  transition: 0.3s;
  cursor: pointer;

  & svg {
    margin-right: 10px;
  }

  &:hover {
    color: var(--color-primary);
  }

  & a {
    transition: 0.3s;
    color: var(--color-black);
    display: flex;
    align-items: center;

    &:hover {
      color: var(--color-primary);
    }

    & svg {
      margin-right: 10px;
    }
  }
`

export const MenuBackground = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
`
