import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { handleLogin } from 'application/usecases/handle-login'

import { Button, InputLabel } from 'view/components'
import { cnpjMask, unmask } from 'view/helpers'

import { setInterceptors } from 'infra/adapters/http-client-adapter'
import { useState } from 'react'
import * as S from './styles'
import { validation } from './validation'

export const LoginPage = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues: { cnpj: '', user: '', password: '' },
    validationSchema: yup.object(validation(yup)),
    onSubmit: () =>
      handleLogin
        .handle({
          cnpj: unmask(formik.values.cnpj),
          user: formik.values.user,
          password: formik.values.password
        })
        .then((data) => {
          if (!data) throw new Error()

          localStorage.setItem(
            '@barrosAuth',
            JSON.stringify({
              accessToken: data.access_token,
              cnpj: unmask(formik.values.cnpj),
              expiresIn: new Date().getTime() + 1000 * 60 * 60, // 1 hour
              customerGuid: data.user_guid,
              clientsId: data.clientesId,
              client: 'RAZÃO SOCIAL DO CLIENTE'
            })
          )

          setInterceptors(data.access_token)
          navigate('/')
        })
        .catch((error) => {
          setError(
            error?.response?.data?.Mensagem ?? 'Usuário ou senha inválidos'
          )
        })
  })

  return (
    <S.Wrapper>
      {/* <Link to="/">
        <img src="/logo.png" alt="logo" />
      </Link> */}
      <S.Card>
        <S.Login>
          <h1>Login</h1>
          <form onSubmit={formik.handleSubmit}>
            <InputLabel
              name="cnpj"
              label="CNPJ"
              formik={formik}
              mask={cnpjMask}
            />
            <InputLabel name="user" label="Usuário" formik={formik} />
            <InputLabel
              type="password"
              name="password"
              label="Senha"
              formik={formik}
            />

            <div style={{ color: 'red', fontSize: 14 }}>{error}</div>

            <Link to="/recuperar-senha">Esqueci minha senha</Link>

            <Button
              disabled={formik.isSubmitting}
              type="submit"
              color="--color-red"
            >
              {formik.isSubmitting ? 'Entrando...' : 'Entrar'}
            </Button>
          </form>
        </S.Login>
      </S.Card>
    </S.Wrapper>
  )
}
