export class ListPromotionItemService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(oldParams) {
    const params = {
      Clientesid: Number(oldParams.customer),
      GruposId: 0,
      CategProdId: 0,
      SubcategId: 0,
      Filtro: oldParams.filter,
      Pagesize: oldParams.pageSize,
      Pagenumber: oldParams.pageNumber,
      Promotipoid: oldParams.promotionTypeId
    }

    return await this.httpClient.get('/itens/itenspromocao', { params })
  }
}
