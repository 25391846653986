import { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { handleShowItem } from 'application/usecases/handle-show-item'

import { Container } from 'view/components'

import { Details } from './details'
import { Similar } from './similar'

import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { handleShowItemSimilar } from 'application/usecases/handle-show-item-similars'

export const ShowItemPage = () => {
  const params = useParams()
  const [item, setItem] = useState({})
  const [product, setProduct] = useState(0)
  const [productSimilars, setProductSimilars] = useState([])
  const [loadingSimilars, setLoadingSimilars] = useState(false)
  const conditions = useSelector((state) => state.conditions)
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))

  useEffect(() => {
    getValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const fetch = useCallback(() => {
    handleShowItem
      .handle({
        ClientesId: Number(token?.clientsId),
        Itensid: params.id
      })
      .then((res) => {
        setItem(res.data.ListaDetalhes[0])
      })
      .catch(() => toast.error('Erro ao buscar os detalhes do produto'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const similars = useCallback(() => {
    setLoadingSimilars(true)

    handleShowItemSimilar
      .handle({
        ClientesId: Number(token?.clientsId),
        Itensid: params.id
      })
      .then((res) => {
        setProductSimilars(res.data.ListaItens)
      })
      .catch(() => toast.error('Erro ao buscar produtos similares'))
      .finally(() => setLoadingSimilars(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const getValue = async () => {
    const cookieProduct = Cookies.get('@barrosProduct')
    if (!cookieProduct) return

    const product = await JSON.parse(cookieProduct)

    if (!item.ID || !product) return

    try {
      if ((product.ItensId && item.ID) || (product.ItemId && item.ID)) {
        if (product.ItensId === item.ID || product.ItemId === item.ID)
          setProduct(product)
        else throw new Error()
      } else throw new Error()
    } catch {
      toast.error('Infelizmente ocorreu um erro, por favor tente novamente.')
    }
  }

  useEffect(() => {
    fetch()
    similars()

    return () => {}
  }, [fetch, similars])

  return (
    <>
      <Container>
        <Details
          item={item}
          product={product}
          conditionId={conditions.selected}
          conditionPercentage={
            conditions.list.length && conditions.selected
              ? conditions.list.find((c) => c.cPagtoId === conditions.selected)
                  ?.pPercentual
              : 0
          }
        />

        <Similar
          item={productSimilars}
          conditions={conditions}
          loadingSimilars={loadingSimilars}
        />
      </Container>
    </>
  )
}
