import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`
export const PaginationBox = styled.div`
  background-color: var(--color-white);
  border: 1px solid var(--color-medium-grey);
  border-radius: 5px;
  padding: 10px 0;
`
export const PaginationContinousItem = styled.span`
  padding: 10px;
`
export const PaginationItem = styled.span`
  padding: 10px;
  border-left: 1px solid var(--color-medium-grey);
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
export const PaginationActive = styled(PaginationItem)`
  color: var(--color-white);
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
`
