import { Footer } from './styles'

export const FooterBar = () => {
  return (
    <Footer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 8px'
        }}
      >
        <div />
        <span>Dealer CRM 2022 © Todos os direitos reservados.</span>
        <span style={{ fontSize: 12, opacity: 0.5 }}>v1.6.3.8</span>
      </div>
    </Footer>
  )
}
