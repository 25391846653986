import styled from 'styled-components'

export const PercentPromotionContainer = styled.h5`
  display: flex;
  align-items: center;
  height: 22px;
  width: auto;
  font-size: 13px;
  color: white;
  background-color: ${(props) => props.color || 'green'};
  padding: 4px 6px;
  border-radius: 5px;

  & svg {
    margin-left: 5px;
  }
`
