import { createSlice } from '@reduxjs/toolkit'

export const conditionsSlice = createSlice({
  name: 'conditions',
  initialState: {
    list: [],
    selected: "",
    selectedDescription: ""
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setSelectedDescription: (state, action) => {
      state.selectedDescription = action.payload
    }
  }
})

export const {
  setList,
  setSelected,
  setSelectedDescription
} = conditionsSlice.actions

export default conditionsSlice.reducer
