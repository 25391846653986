import { useNavigate } from 'react-router-dom'
import { Card } from 'view/components'
import { Stack } from 'view/components/Stack'
import {
  NotificationButton,
  NotificationCardContainer,
  NotificationModalContainer
} from './styles'

export const NotificationCard = ({ handleClose }) => {
  const navigate = useNavigate()

  const handleNavigateToOrder = () => {
    navigate('/pedidos')
    handleClose()
  }

  return (
    <Card>
      <NotificationCardContainer>
        <p>
          Existem pedidos em aberto do dia anterior, e o mesmo podem sofrer
          alteração de preço.
        </p>

        <Stack align="end" justify="end" gap="8px" m="16px 0 0 0">
          <NotificationButton variant="primary" onClick={handleNavigateToOrder}>
            Ir para pedidos
          </NotificationButton>
          <NotificationButton onClick={handleClose}>Fechar</NotificationButton>
        </Stack>
      </NotificationCardContainer>
    </Card>
  )
}

export const NotificationModal = ({ show, children }) => {
  return (
    show && <NotificationModalContainer>{children}</NotificationModalContainer>
  )
}
