import React from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

import * as S from './styles'

export const Table = ({ header, sortList, children }) => {
  return (
    <S.Table>
      <thead>
        <tr>
          {header.map((element, index) => (
            <th
              key={index}
              onClick={element.click ?? false}
              style={{ cursor: element.click ? 'pointer' : 'default' }}
            >
              {element.label}
              {sortList
                ? sortList[element.sortCheckerName]?.isCrescent && (
                    <AiFillCaretUp />
                  )
                : ''}

              {sortList
                ? sortList[element.sortCheckerName]?.isDecrescent && (
                    <AiFillCaretDown />
                  )
                : ''}
            </th>
          ))}
          <th width="50"></th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </S.Table>
  )
}
