import { Card } from '../card'
import { Stack } from '../Stack'
import {
  NotificationButton,
  NotificationCardContainer,
  NotificationModalContainer
} from './styles'

export const MinValue = ({ condition, onClose }) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Card>
      <NotificationCardContainer>
        <p>
          O valor mínimo para a condição {condition?.dDescricao ?? ''} é{' '}
          {(Number(condition?.vValorMin) ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </p>

        <Stack align="end" justify="end" gap="8px" m="16px 0 0 0">
          <NotificationButton variant="primary" onClick={handleClose}>
            Fechar
          </NotificationButton>
        </Stack>
      </NotificationCardContainer>
    </Card>
  )
}

export const NotificationModal = ({ show, children }) => {
  return (
    show && <NotificationModalContainer>{children}</NotificationModalContainer>
  )
}
