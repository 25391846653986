import styled from 'styled-components'

export const Content = styled.div`
  padding: 10px;
`

export const HomePageContainer = styled.div`
  width: 100%;
`

export const Title = styled.h1`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: ${({ background }) =>
    background ? background : '#c41104'};
  color: ${({ color }) => (color ? color : '#fff')};
  border-radius: 5px;
  font-weight: 300;
  font-size: 1.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 0.98rem;
  }
`

export const CarouselContainer = styled.div`
  position: relative;

  background-color: transparent;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 768px) {
    & .slick-slider {
      grid-template-columns: 100%;
    }
  }
`

export const Carrousel = styled.div`
  display: flex;
  overflow-x: hidden;
  gap: 15px;
  transition: 0.5s;
`

export const ArrowLeft = styled.button`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.3s;
  left: 0;
  top: ${({ notShouldCenter }) => (notShouldCenter ? '' : '50%')};
  transform: 'translateY(-50%)';
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #3d3d3d95;
  font-size: 25px;
  border-radius: 70px;
  z-index: 999;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #c41104;
  }
`
export const ArrowRight = styled.button`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.3s;
  right: 0;
  top: ${({ notShouldCenter }) => (notShouldCenter ? '' : '50%')};
  transform: 'translateY(-50%)';
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #3d3d3d95;
  font-size: 25px;
  border-radius: 70px;
  z-index: 999;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #c41104;
  }
`

export const ProductsContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-bottom: 50px;
`

export const SeeAllProducts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 6px 15px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.8);
  width: auto;
  cursor: pointer;
  transition: 0.3s;
  font-size: 0.95rem;
  font-weight: 400;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`
