import { useEffect } from 'react'
import { useRef, useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { PageTitle, Spinner } from 'view/components'
import { CardProductDefault } from 'view/components/card-product-default/CardProductDefault'

import * as S from './styles'

export const Similar = ({ item, conditions, loadingSimilars }) => {
  const [scrollIds, setScrollIds] = useState(
    item && item.length
      ? item.map((product, index) => ({
          id: String(product.ItensId),
          active: index === 0 ? true : false
        }))
      : []
  )
  const [scrollLeftCorner, setScrollLeftCorner] = useState(true)
  const [scrollRightCorner, setScrollRightCorner] = useState(false)
  const carrouselRef = useRef()

  useEffect(() => {
    setScrollIds(
      item && item.length
        ? item.map((product, index) => ({
            id: String(product.ItensId),
            active: index === 0 ? true : false
          }))
        : []
    )
  }, [item])

  function handleClickArrowLeft() {
    const i = scrollIds.findIndex((s) => s.active)
    if (carrouselRef.current.scrollLeft <= 0) return setScrollLeftCorner(true)

    document
      .getElementById(scrollIds[i - 1].id)
      .scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' })

    scrollIds[i].active = false
    scrollIds[i - 1].active = true
    setScrollIds([...scrollIds])

    if (carrouselRef.current.scrollLeft <= 275) return setScrollLeftCorner(true)

    setScrollLeftCorner(false)
    setScrollRightCorner(false)
  }

  function handleClickArrowRight() {
    const i = scrollIds.findIndex((s) => s.active)
    if (
      carrouselRef.current.offsetWidth + carrouselRef.current.scrollLeft >=
      carrouselRef.current.scrollWidth
    )
      return setScrollRightCorner(true)

    document
      .getElementById(scrollIds[i + 1].id)
      .scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' })

    scrollIds[i].active = false
    scrollIds[i + 1].active = true
    setScrollIds([...scrollIds])

    if (
      carrouselRef.current.offsetWidth + carrouselRef.current.scrollLeft >=
      carrouselRef.current.scrollWidth - 275
    )
      return setScrollRightCorner(true)

    setScrollRightCorner(false)
    setScrollLeftCorner(false)
  }

  return (
    <>
      <PageTitle>Também podem ser do seu interesse</PageTitle>

      {loadingSimilars && (
        <div style={{ position: 'relative', height: 300 }}>
          <Spinner size={60} absolute />
        </div>
      )}
      {item &&
        item.length > 0 &&
        !loadingSimilars &&(
          <S.CarouselContainer>
            {!scrollLeftCorner && (
              <S.ArrowLeft onClick={() => handleClickArrowLeft()}>
                <FaAngleLeft />
              </S.ArrowLeft>
            )}

            {!scrollRightCorner && (
              <S.ArrowRight onClick={() => handleClickArrowRight()}>
                <FaAngleRight />
              </S.ArrowRight>
            )}

            <S.Carrousel ref={carrouselRef}>
              {item &&
                item.length > 0 &&
                item.map((product, index) => {
                  return (
                    <CardProductDefault
                      id={product.ItensId}
                      key={index}
                      product={product}
                      conditionPercentage={
                        conditions.list.length && conditions.selected
                          ? conditions.list.find((c) => {
                              return c.cPagtoId === conditions.selected
                            }).pPercentual
                          : 0
                      }
                    />
                  )
                })}
            </S.Carrousel>
          </S.CarouselContainer>
        )}
    </>
  )
}
