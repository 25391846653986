import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { handleListTitulo } from 'application/usecases/handle-list-titulo'
import { setTituloList, setCedente } from 'application/store/titulos'

import { Container, PageTitle, Table, Button } from 'view/components'
import { toMoney } from 'view/helpers'

import * as S from './styles'
import { handleSendTitle } from 'application/usecases/handle-send-title'
import { NotificationTitle } from './notifications'
import { NotificationModal } from 'layout/notification-modal'

export const ListTituloPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [observations, setObservations] = useState('')
  const titulos = useSelector((state) => state.titulos)
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const dispatch = useDispatch()

  useEffect(() => {
    handleListTitulo
      .handle({
        Dataini: '',
        Datafim: '',
        Clientesid: Number(token?.clientsId)
      })
      .then((res) => {
        dispatch(setTituloList(res.data.Boletos.boletos))
        return res
      })
      .then((res) => {
        dispatch(setCedente(res.data.Boletos.cedente))
        return res
      })
      .catch(() => toast.error('Não foi possível listar os boletos'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const header = [
    { label: 'NF' },
    { label: 'Emissão' },
    { label: 'Vencimento' },
    { label: 'Parcela' },
    { label: 'Saldo' }
  ]

  const handleSend = async (id) => {
    setIsLoading(true)

    try {
      const { data } = await handleSendTitle.handle({
        TitulosId: id
      })

      toast.success('Boleto enviado com sucesso')

      if (data.Messages) setObservations(data.Messages)
    } catch (error) {
      toast.error('Não foi possível enviar o boleto')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <NotificationModal show={!!observations}>
        <NotificationTitle
          observations={observations}
          handleClose={() => setObservations('')}
        />
      </NotificationModal>

      <Container>
        <PageTitle>Boletos</PageTitle>
        <Table header={header}>
          {titulos.tituloList.map((item) => {
            return (
              <tr key={item.cPedido}>
                <td>{item.numeroDocumento}</td>
                <td>{dayjs(item.dataDocumento).format('DD/MM/YY')}</td>
                <td>{dayjs(item.vencimento).format('DD/MM/YY')}</td>
                <td>{item.parcela}</td>
                <td>{toMoney(item.valorDocumento)}</td>
                <S.Td>
                  <Button
                    small
                    disabled={isLoading}
                    color="--color-green"
                    onClick={() => handleSend(item.TitulosID)}
                  >
                    Enviar
                  </Button>
                </S.Td>
              </tr>
            )
          })}
        </Table>
      </Container>
    </>
  )
}
