import styled from 'styled-components'

export const Title = styled.div`
  position: relative;
  margin: 20px 0 10px 0;
  display: flex;
  align-items: center;
  & h1 {
    margin: 0;
    padding: 20px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    & {
      padding: 0 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export const Filter = styled.div`
  margin: 10px 10px 0 0;
  /* height: 500px; */
  width: 300px;
  & h3 {
    font-weight: 500;
    color: var(--color-primary);
    margin: 16px 0 5px 0;
  }
  & div {
    padding: 4px;
  }

  @media (max-width: 1024px) {
    & {
      width: 250px;
    }
  }

  @media (max-width: 768px) {
    & {
      display: none;
    }
  }
`
export const FilterCheckboxes = styled.div`
  background: #fff;
  /* height: 500px; */
  border: 1px solid var(--color-dark-grey);
  overflow-y: scroll;
  scrollbar-color: var(--color-medium-grey);
  scrollbar-width: thin;
  margin-bottom: 48px;
  & div {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
`

export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  margin: 10px 0;

  @media (max-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 10px;
  }
`

export const WrapperCards = styled.div`
  min-width: 220px;
  width: auto;
  background-color: #fff;
  border-radius: 5px;
`

export const ContentPage = styled.div`
  display: flex;
`

export const FiltersSelected = styled.div`
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  & p {
    margin: 0;
  }

  & div label {
    padding: 10px;
  }
  & div select {
    padding: 5px;
  }
  & div select:focus {
    outline: none;
  }
`

export const FilterItems = styled.div`
  display: flex;
  margin-right: auto;
  @media (max-width: 960px) {
    & {
      display: none;
    }
  }
`

export const FilterItem = styled.div`
  font-size: 12px;
  background-color: var(--color-medium-grey);
  color: var(--color-white);
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 0 8px;
  & svg {
    font-size: 20px;
    margin-left: 8px;
    cursor: pointer;
  }
  @media (max-width: 960px) {
    & {
      margin-top: 10px;
    }
  }
`

export const SubCategoryList = styled.div`
  display: flex;
  flex-direction: column;
  & div {
    width: 100%;
    padding-left: 24px;
    display: flex;
    align-items: flex-start;
  }
`

export const InputCategory = styled.div`
  & label {
    font-weight: 700;
  }
`

export const Checkbox = styled.input`
  margin-right: 8px;
`

export const ModalContent = styled.div`
  padding: 30px;
  min-width: 300px;
`

export const ButtonToMobileCategories = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: gray;
  color: var(--color-white);
  font-size: 15px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  & svg {
    font-size: 21px;
    margin-right: 8px;
  }

  @media (min-width: 769px) {
    & {
      display: none;
    }
  }
`
