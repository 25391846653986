import styled from 'styled-components'

// export const Container = styled.div`
//   // 4 cards e categproa com tamanho de 300px
//   width: 1380px;

//   // 3 cards categoria com tamanho de 300px
//   @media (max-width: 1366px) {
//     width: 1120px;
//   }

//   // 2 cards categoria com tamanho de 250px
//   @media (max-width: 1024px) {
//     width: 795px;
//   }

//   // 2 cards sem categoria
//   @media (max-width: 768px) {
//     width: 535px;
//   }

//   // 2 cards de 210px sem categoria
//   @media (max-width: 480px) {
//     width: auto;
//   }
// `

export const Container = styled.div`
  width: 100%;
  padding: 10px;
`
