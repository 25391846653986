import styled from 'styled-components'

export const Breadcrumb = styled.div`
  padding: 8px 0;
  list-style: none;
  & li {
    display: inline;
    font-size: 16px;
  }
  & li + li:before {
    color: var(--color-grey-1);
  }
  & li a {
    color: var(--color-primary);
    text-decoration: none;
    padding: 8px;
    border-radius: 5px;
  }
  & li {
    color: var(--color-grey-2);
  }
  & li a:hover {
    background-color: var(--color-grey-1);
    text-decoration: none;
  }
`
