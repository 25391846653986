export class DeleteAllOrdersService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle({ cPedido }) {
    return await this.httpClient.post(`/pedidos/ExcluiPedido/`, {
      ExcPedido: {
        cPedido,
        cItem: ''
      }
    })
  }
}
