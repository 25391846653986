import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { FaEye } from 'react-icons/fa'

import {
  setOrderList,
  setOrderTotalItems,
  sortList
} from 'application/store/orders'

import { toMoney } from 'view/helpers'
import { Container, PageTitle, Table, Link } from 'view/components'

import * as S from './styles'
import { handleListOrder } from 'application/usecases/handle-list-order'
import { PaginationOrders } from 'view/components/pagination-order'
import { toast } from 'react-toastify'

export const ListOrderPage = () => {
  const orders = useSelector((state) => state.orders)
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))
  const dispatch = useDispatch()

  useEffect(() => {
    handleListOrder
      .handle({
        cCliente: Number(token?.clientsId)
      })
      .then((res) => {
        if (!res.data.Pedido || !res.data.cRegRetorno) throw new Error()

        dispatch(
          setOrderList(
            res.data.Pedido.sort(
              (a, b) => new Date(b?.dtEmissao) - new Date(a?.dtEmissao)
            )
          )
        )

        dispatch(setOrderTotalItems(res.data.cRegRetorno))
      })
      .catch(() => toast.error('Não foi possível listar os pedidos'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orders.page])

  const header = [
    {
      label: 'Número',
      sortCheckerName: 'code',
      click: () =>
        dispatch(
          sortList({ sortCheckerName: 'code', fieldToSort: 'cPedExterno' })
        )
    },
    { label: 'Status' },
    {
      label: 'Abertura',
      sortCheckerName: 'date',
      click: () =>
        dispatch(
          sortList({ sortCheckerName: 'date', fieldToSort: 'dtEmissao' })
        )
    },
    {
      label: 'Valor',
      sortCheckerName: 'value',
      click: () =>
        dispatch(
          sortList({ sortCheckerName: 'value', fieldToSort: 'vPedTotal' })
        )
    }
  ]

  return (
    <Container>
      <PageTitle>Pedidos</PageTitle>
      <Table header={header} sortList={orders.sortList}>
        {orders.orderList
          ? orders.orderList
              .slice(orders.page * 10, orders.page * 10 + 10)
              .map((item) => {
                return (
                  <tr key={item.cPedido}>
                    <td>{item.cPedExterno}</td>
                    <td>
                      <S.Status color="rgba(255, 127, 39, .3)">
                        {item.dFases}
                      </S.Status>
                    </td>
                    <td>{dayjs(item.dtEmissao).format('DD/MM/YY')}</td>
                    <td>{toMoney(item.vPedTotal)}</td>
                    <S.Td>
                      <Link
                        to={`/pedidos/${item.cPedido}`}
                        color="--color-green"
                        small
                      >
                        <FaEye />
                      </Link>
                    </S.Td>
                  </tr>
                )
              })
          : ''}
      </Table>
      <PaginationOrders />
    </Container>
  )
}
