import styled, { css } from 'styled-components'

export const Standart = styled.button`
  border-radius: 5px;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:disabled:hover {
    opacity: 0.5;
  }
  @media (max-width: 960px) {
    & {
      font-size: 14px;
    }
  }
`

const wrapperModifiers = {
  block: () => css`
    width: 100% !important;
  `
}

export const Button = styled(Standart)`
  ${({ borderColor, color, colorFont, fontSize, block, small }) => css`
    border: 1px solid var(${borderColor ? borderColor : color});
    padding: ${!!small ? '4px 6px' : '8px 12px'};
    background-color: var(${color});
    color: var(${colorFont ? colorFont : '--color-white'});
    font-size: ${`${fontSize ? fontSize : 16}px`};
    ${!!block && wrapperModifiers.block()};
  `}

  transition: .3s;

  &:hover {
    ${({ hoverColor }) => hoverColor && 'color: ' + hoverColor};
  }
`
