import { Button } from 'view/components'
import {
  FilterCheckboxes,
  FilterCheckboxesContainer,
  FilterContainer,
  InputCategory,
  SubCategoryList
} from './styles'

export function Categories({
  show,
  categories,
  productsLength,
  handleChangeCategory,
  handleChangeSubcategory,
  handleClose,
  setShowCarsModal,
  loading
}) {
  return (
    <>
      {show && (
        <div
          onClick={handleClose}
          style={{
            background: 'black',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0
          }}
        />
      )}
      <FilterContainer show={show}>
          {/* <Button
            color="--color-white"
            colorFont="--color-dark-grey"
            hoverColor="#C41104"
            borderColor="--color-dark-grey"
            onClick={() => setShowCarsModal(true)}
            block
            disabled={!!!productsLength || loading}
          >
            Selecione seu veículo
          </Button> */}
          <h3>Categorias ({categories?.length})</h3>
          <FilterCheckboxes>
            <FilterCheckboxesContainer>
              {categories.map((category, index) => {
                return (
                  <span key={index}>
                    <InputCategory>
                      <div
                        onClick={() => handleChangeCategory(category)}
                        disabled={loading}
                      >{`${category?.ItensCategDescri}`}</div>
                    </InputCategory>

                    <SubCategoryList>
                      {category?.ListaSubCategorias &&
                        category?.ListaSubCategorias.map(
                          (subcategory, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handleChangeSubcategory(subcategory)
                              }
                            >{`${subcategory?.ItensSubCategDescri}`}</div>
                          )
                        )}
                    </SubCategoryList>
                  </span>
                )
              })}
            </FilterCheckboxesContainer>
          </FilterCheckboxes>
      </FilterContainer>
    </>
  )
}
