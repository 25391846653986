import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  AiOutlineBarcode,
  AiOutlineShoppingCart,
  AiOutlineHome
} from 'react-icons/ai'
import { BsPersonCircle } from 'react-icons/bs'
import { BiExit } from 'react-icons/bi'
import {
  ContextMenu,
  ContextMenuItem,
  Menu,
  MenuBackground,
  MenuItem,
  MenuItemLabel
} from './styles'
import { cnpjMask, toMoney } from 'view/helpers'
import { useShoppingCart } from 'hooks/useShoppingCart'
import { useSelector } from 'react-redux'
import { ChangePassword } from './ChangePassword'
import { HiOutlineKey } from 'react-icons/hi'

export const MenuBar = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [isOpenModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const cnpj = JSON.parse(localStorage.getItem('@barrosAuth'))?.cnpj ?? ''
  const shoppingCart = useSelector((state) => state.shoppingCart)
  const { getAmount, resetAmount } = useShoppingCart()

  useEffect(() => {
    getAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeModal = () => {
    setOpen(!isOpen)
  }

  const logout = () => {
    localStorage.removeItem('@barrosAuth')
    resetAmount()
    navigate('/login')
  }

  return (
    <>
      <Menu>
        <Link to="/">
          <MenuItem>
            <AiOutlineHome />
            <MenuItemLabel>Início</MenuItemLabel>
          </MenuItem>
        </Link>

        <Link to="/titulos">
          <MenuItem>
            <AiOutlineBarcode />
            <MenuItemLabel>Boletos</MenuItemLabel>
          </MenuItem>
        </Link>

        <Link to="/pedidos">
          <MenuItem>
            <AiOutlineShoppingCart />
            <MenuItemLabel>{toMoney(shoppingCart?.amount ?? 0)}</MenuItemLabel>
          </MenuItem>
        </Link>

        <MenuItem style={{ position: 'relative' }} onClick={handleChangeModal}>
          <BsPersonCircle />
          <span style={{ marginLeft: 4 }}>{cnpjMask(cnpj)}</span>

          <MenuBackground isOpen={isOpen} onClick={handleChangeModal} />
          <ContextMenu isOpen={isOpen}>
            <ContextMenuItem
              onClick={() => {
                setOpen(false)
                setOpenModal(true)
              }}
            >
              <HiOutlineKey /> Alterar senha
            </ContextMenuItem>

            <ContextMenuItem onClick={logout}>
              <BiExit /> Sair
            </ContextMenuItem>
          </ContextMenu>
        </MenuItem>
      </Menu>
      <ChangePassword isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
    </>
  )
}
