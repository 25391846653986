import React from 'react'

import * as S from './styles'

export const Modal = ({
  id = 'modal',
  visibleModal,
  setVisibleModal,
  children
}) => {
  const onClose = setVisibleModal
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose()
  }

  return (
    visibleModal && (
      <S.Modal id={id} onClick={handleOutsideClick}>
        {children}
      </S.Modal>
    )
  )
}
