import { Navigate, Outlet } from 'react-router-dom'
import { setInterceptors } from 'infra/adapters/http-client-adapter'

export const PrivateRoute = () => {
  const barrosAuth = JSON.parse(localStorage.getItem('@barrosAuth'))

  if (
    !barrosAuth ||
    !barrosAuth.accessToken ||
    !barrosAuth.cnpj ||
    barrosAuth.expiresIn < new Date().getTime()
  ) {
    localStorage.removeItem('@barrosAuth')
    return <Navigate to="/login" />
  }

  setInterceptors(barrosAuth.accessToken)
  return <Outlet />
}
