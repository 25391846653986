import { createSlice } from '@reduxjs/toolkit'

export const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    refresh: false,
    orderList: [],
    order: [],
    itemList: [],

    // sort
    sortList: {
      code: { isCrescent: false, isDecrescent: false, isNumeric: true },
      date: { isCrescent: false, isDecrescent: false, isDate: true },
      value: { isCrescent: false, isDecrescent: false, isFloat: true }
    },

    page: 0,
    itemTotalItems: 0,
  },
  reducers: {
    setRefresh: (state, action) => {
      state.refresh = action.payload
    },
    setOrderList: (state, action) => {
      state.orderList = action.payload
    },
    setOrder: (state, action) => {
      state.order = action.payload
    },
    setItemList: (state, action) => {
      state.itemList = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setOrderTotalItems: (state, action) => {
      state.itemTotalItems = action.payload
    },
    sortList: (state, action) => {
      let { sortCheckerName, fieldToSort } = action.payload
      const { isCrescent, isDecrescent, isNumeric, isFloat, isDate } =
        state.sortList[sortCheckerName]

      if (isDecrescent) {
        state.sortList[sortCheckerName].isDecrescent = false
        return
      }

      state.orderList = state.orderList.sort((a, b) => {
        if (isNumeric) {
          return isCrescent
            ? Number(a[fieldToSort]) - Number(b[fieldToSort])
            : Number(b[fieldToSort]) - Number(a[fieldToSort])
        }

        if (isFloat) {
          return isCrescent
            ? parseFloat(a[fieldToSort]) - parseFloat(b[fieldToSort])
            : parseFloat(b[fieldToSort]) - parseFloat(a[fieldToSort])
        }

        if (isDate) {
          return isCrescent
            ? new Date(a[fieldToSort]) - new Date(b[fieldToSort])
            : new Date(b[fieldToSort]) - new Date(a[fieldToSort])
        }

        return 0
      })

      if (!isCrescent && !isDecrescent) {
        state.sortList[sortCheckerName].isCrescent = true
        return
      }

      if (isCrescent) {
        state.sortList[sortCheckerName].isCrescent = false
        state.sortList[sortCheckerName].isDecrescent = true
        return
      }
     }
  }
})

export const {
  setRefresh,
  setOrderList,
  setOrder,
  setItemList,
  setPage,
  setOrderTotalItems,
  sortList
} = orderSlice.actions

export default orderSlice.reducer
