import styled from 'styled-components'

export const ProductsBox = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const ItemCard = styled.div`
  min-width: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
  text-decoration: none;
  color: var(--color-font);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  & form {
    display: flex;
    width: 100%;
  }
  @media (max-width: 1440px) {
    & {
      width: 30%;
    }
  }
  @media (max-width: 1140px) {
    & {
      width: 40%;
    }
  }
  @media (max-width: 540px) {
    & {
      width: 100%;
    }
  }
`
export const BuyBox = styled.div`
  min-width: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
  text-decoration: none;
  color: var(--color-font);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px 15px 15px;
`

export const ProductCardPrice = styled.div``

export const CardPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  justify-content: space-between;
`

export const CardPriceOld = styled.h5`
  color: rgb(100, 100, 100);
  font-weight: 500;
`

export const PercentPromotion = styled.h5`
  height: 22px;
  width: auto;
  font-size: 12px;
  color: white;
  background-color: ${(props) => props.color || 'green'};
  padding: 4px 6px;
  border-radius: 5px;
`

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 200px;
  padding: 0;
  border-bottom: 1px solid #eee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  & a {	
    padding: 0;
  }

  & img {
    height: 200px;
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

export const Application = styled.p`
  font-size: 15px;
  margin: 5px 0;
`
export const Description = styled.p`
  margin: 5px 0;
  font-size: 15px;
  font-weight: 500;
`
export const Form = styled.form`
  padding: 5px 0 10px 0;
  display: flex;
  & input,
  button {
    width: 50%;
  }
`
export const ApplicationBox = styled.div`
  display: flex;
`
