export class LoginService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(userData) {
    const data = new URLSearchParams()
    data.append('client_id', '5d379db76ff1493ba450bb296f31dd7a')
    data.append('grant_type', 'password')
    data.append('scope', 'FullControl')
    data.append('username', userData.user)
    data.append('password', userData.password)

    const res = await this.httpClient.post('/oauth/access_token', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

    const resValidate = await this.validateDocument({
      cnpj: userData?.cnpj,
      userGuid: res.data?.user_guid
    })

    return {
      ...res.data,
      ...resValidate.data
    }
  }

  async validateDocument(userData) {
    return await this.httpClient.get(`/v01/Usuario/validaUsuarioCNPJ`, {
      params: {
        ClientesCNPJ: userData.cnpj,
        UserGuid: userData.userGuid
      }
    })
  }
}
