import styled from 'styled-components'

export const NotificationModalContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
`

export const NotificationCardContainer = styled.div`
  background-color: white;
  width: auto;
  max-width: 550px;
  min-width: 300px;
  padding: 30px 25px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.5);
`

export const NotificationButton = styled.button`
  background-color: ${({ variant }) =>
    variant === 'primary' ? '#C41104' : 'transparent'};
  color: ${({ variant }) =>
    variant === 'primary' ? 'white' : 'rgba(0, 0, 0, 0.8)'};
  cursor: pointer;
  border: none;
  font-weight: 400;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  opacity: 0.9;
  transition: 0.3s;

  &:hover {
    background-image: linear-gradient(rgb(0 0 0/7%) 0 0);
    opacity: 1;
  }
`
