import { Logotipo } from './Logotipo'
import { Search } from './Search'
import { MenuBar } from './Menu'
import { MenuMobile } from './MenuMobile'
import { Header, Content, TopbarSearchBox } from './styles'
import { Conditions } from '../../view/components/conditions'

export const Nav = () => {
  return (
    <Header>
      <Content>
        <MenuMobile />
        <Logotipo />
        <TopbarSearchBox>
          <Search />
        </TopbarSearchBox>
        <MenuBar />
        <Conditions />
      </Content>
    </Header>
  )
}
