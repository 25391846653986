const { default: styled } = require('styled-components')

export const CardContainer = styled.form`
  background-color: #fff;
  width: 260px;
  max-width: 260px;
  min-width: 260px;
  height: auto;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`

export const CardImage = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #eee;
  position: relative;
  cursor: pointer;

  & a {
    padding: 0;
  }

  & img {
    max-height: 100%;
    width: auto;
  }

  & h2 {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 220px);
  padding: 15px;
`

export const CardTitle = styled.p`
  max-width: 230px;
  font-weight: bold;
  color: #333;
  font-size: 15px;
  margin-bottom: 10px;
  cursor: pointer;
`

export const CardInfo = styled.p`
  font-size: 15px;
  margin: 3px 0;
  color: ${(props) => props.color ?? 'black'};
  word-wrap: break-word;
`

export const CardBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`

export const CardConfirmBuy = styled.div`
  display: flex;
  margin: 0 0 10px 0;

  & span {
    margin-right: 10px;
  }
`

export const CardInput = styled.input`
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;

  &:focus {
    outline: none;
  }
`

export const CardButton = styled.button`
  cursor: pointer;
  background: ${({ background, contained }) =>
    contained ? background || 'transparent' : 'transparent'};
  color: ${({ color }) => (color ? color : '#333')};
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: ${({ contained, color }) =>
    contained ? 'none' : '1px solid ' + (color ? color : '#333')};
  transition: all 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(0.6);
  }

  &:disabled {
    /* color: #9c9c9c; */
    /* outline-color: #9c9c9c; */
    /* background-color: #ebebeb; */
    filter: none;
    cursor: default;
  }

  &:disabled:hover {
    /* color: #9c9c9c; */
    /* outline-color: #9c9c9c; */
    /* background-color: #ebebeb; */
    filter: none;
  }
`
