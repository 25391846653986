import styled from 'styled-components'

export const Wrapper = styled.div`
  /* background-color: var(--color-black); */
  background-image: url('/FUNDO-B2B-BARROS.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  & img {
    margin: 50px 0;
  }
`
export const Card = styled.div`
  padding: 100px 15px 15px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const Login = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 5px;
  max-width: 400px;
  background-color: #ccc;
  border: 1px solid var(--color-dark-grey);
  background-color: var(--color-light-grey);
  & h1 {
    width: 100%;
    margin-bottom: 16px;
  }
  & form {
    display: flex;
    flex-direction: column;
  }
  & form button {
    margin-top: 20px;
  }
`
