export class ListItemService {
  constructor(httpClient) {
    this.httpClient = httpClient
  }

  async handle(controllerSignal, oldParams, promotionTypeId, isPromotion) {
    const params = {
      Clientesid: Number(oldParams.Clientesid),
      Pagesize: oldParams.Pagesize,
      Pagenumber: oldParams.Pagenumber,
      Gruposid: oldParams.Gruposid,
      Categprodid: oldParams.Categprodid,
      Subcategid: oldParams.Subcategid,
      ModelosId: oldParams.ModelosId,
      Filtro: oldParams.Filtro ? oldParams.Filtro : '',
      Itenscodfornec: oldParams.Itenscodfornec ? oldParams.Itenscodfornec : '',
      Veiculoid: oldParams.veiculoid || ''
    }

    if (promotionTypeId || isPromotion) {
      params.Promotipoid = promotionTypeId

      if (!promotionTypeId) delete params.Promotipoid

      return await this.httpClient.get('/itens/itenspromocao', {
        params,
        signal: controllerSignal.signal
      })
    }

    return await this.httpClient.get('/itens/itens', {
      params,
      signal: controllerSignal.signal
    })
  }

  async categories(
    controllerSignal,
    {
      Clientesid,
      Gruposid,
      Categprodid,
      Subcategid,
      ModelosId,
      Filtro,
      Itenscodfornec,
      veiculoid,
      Promotipoid
    }
  ) {
    const params = {
      Clientesid,
      Gruposid,
      Categprodid,
      Subcategid,
      ModelosId,
      Filtro,
      Itenscodfornec,
      Veiculoid: veiculoid
    }

    if (Promotipoid) params.Promotipoid = Promotipoid

    return await this.httpClient.get('/Itens/Categorias', {
      params,
      signal: controllerSignal.signal
    })
  }

  async brands(
    controllerSignal,
    {
      Clientesid,
      Gruposid,
      Categprodid,
      Subcategid,
      ModelosId,
      Filtro,
      Itenscodfornec,
      veiculoid,
      Promotipoid
    }
  ) {
    const params = {
      Clientesid,
      Gruposid,
      Categprodid,
      Subcategid,
      ModelosId,
      Filtro,
      Itenscodfornec,
      Veiculoid: veiculoid
    }

    if (Promotipoid) params.Promotipoid = Promotipoid

    return await this.httpClient.get('/Itens/Marcas', {
      params,
      signal: controllerSignal.signal
    })
  }
}
