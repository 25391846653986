import { useProducts } from 'application/contexts/products'
import { useEffect, useState } from 'react'
import { IoIosClose, IoIosSearch } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, SearchBox } from './styles'

export const Search = () => {
  const [filterDescription, setFilterDescription] = useState('')
  const [filterCode, setFilterCode] = useState('')
  const { loading, abort } = useProducts()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const description = params.get('descricao')
    const code = params.get('codigo')

    if (description) {
      setFilterDescription(description)
      setFilterCode('')
    } else if (code) {
      setFilterCode(code)
      setFilterDescription('')
    }
  }, [location.search])

  const handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault()

    if (loading) return abort()

    if (filterDescription.length < 1) return

    const params = new URLSearchParams(location.search)
    setFilterDescription('')
    setFilterCode('')

    if (!filterDescription && !filterCode) {
      params.delete('descricao')
      params.delete('codigo')
    }

    if (filterDescription) {
      params.set('descricao', filterDescription)
      params.delete('codigo')
    } else if (filterCode) {
      params.set('codigo', filterCode)
      params.delete('descricao')
    }

    params.delete('categoria')
    params.delete('subcategoria')
    params.delete('marca')
    params.delete('pagina')
    params.delete('veiculo')
    params.delete('promocao')
    params.delete('anuncio')

    navigate(`/pesquisa?${params.toString()}`)
  }

  return (
    <>
      <SearchBox onSubmit={handleSubmit}>
        <input
          disabled={loading}
          type="text"
          placeholder="Nome ou Aplicação ou Código"
          title="Busca Nome ou Aplicação ou Código"
          value={filterDescription}
          onChange={(e) => {
            setFilterDescription(e.target.value)
            setFilterCode('')

            const params = new URLSearchParams(location.search)
            params.delete('codigo')
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
          style={{ width: '100%' }}
        />
        {/* <input
          placeholder="Cód. Fabricante"
          value={filterCode}
          onChange={(e) => {
            setFilterCode(e.target.value)
            setFilterDescription('')

            const params = new URLSearchParams(location.search)
            params.delete('descricao')
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
          style={{ width: '50%' }}
        /> */}
        <Button
          id="search-button"
          color="--color-white"
          colorFont="--color-dark-grey"
          type="submit"
          onClick={handleSubmit}
          style={{
            background: loading ? 'white' : undefined,
            color: loading ? 'black' : undefined
          }}
        >
          {loading ? <IoIosClose size={32} /> : <IoIosSearch size={20} />}
        </Button>
      </SearchBox>
    </>
  )
}
