import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { FaTrash } from 'react-icons/fa'

import { handleStoreOrder } from 'application/usecases/handle-store-item'
import { handleDeleteItem } from 'application/usecases/handle-delete-item'
import { setRefresh } from 'application/store/orders'

import { Button, Input, Spinner } from 'view/components'

import Validation from './validation'

import * as S from '../styles'
import { toMoney } from 'view/helpers'
import { useState } from 'react'
import { ErrorMessage } from 'view/components/error-message'
import { useShoppingCart } from 'hooks/useShoppingCart'

export const Item = ({
  item,
  orderId,
  promotionId,
  promotionPercentage,
  finalized,
  minValue
}) => {
  const [deleting, setDeleting] = useState(false)
  const [changing, setChanging] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const { getAmount } = useShoppingCart()

  const formik = useFormik({
    initialValues: { amount: Number(item.vQuantidade) },
    validationSchema: Yup.object(Validation(Yup)),
    validateOnChange: false,
    onSubmit: async () => {
      setError('')

      if (
        Number(formik.values.amount) * Number(item.vUnitario) <
        Number(minValue)
      )
        return setError('O valor mínimo não foi atingido')

      setChanging(true)

      handleStoreOrder
        .handle({
          cCliente: JSON.parse(localStorage.getItem('@barrosAuth'))?.clientsId,
          cItem: item.dItemCod,
          qtd: formik.values.amount,
          vUnit: Number(item.vUnitario),
          cCondicao: promotionId,
          // cFase: 21,
          // cForPagto: 2,
          // cConPagto: promotionId,
          cOperacao: 4
        })
        .then((res) => {
          const hasError =
            res.data?.Messages?.filter(
              (message) =>
                String(message.Id) !== '200' || String(message.Id) !== '201'
            )?.length ?? 0

          if (hasError) {
            res.data.Messages.forEach((message) => {
              if (String(message.Id) !== '200' || String(message.Id) !== '201')
                toast.error(message.Description)
            })
            const errors = res.data.Messages.filter(
              (message) =>
                String(message.Id) !== '200' || String(message.Id) !== '201'
            )

            formik.setFieldValue(
              'amount',
              Number(item.vQuantidade ?? 0).toFixed(0)
            )

            return formik.setFieldError('amount', errors)
          }

          toast.success('Item alterado com sucesso')
          dispatch(setRefresh(true))
        })
        .then(() => {
          try {
            getAmount()
          } catch (err) {
            console.error(err)
          }
        })
        .catch(() => toast.error('Não foi possível adicionar o produto'))
        .finally(() => setChanging(false))
    }
  })

  const deleteItem = useCallback(() => {
    setDeleting(true)

    handleDeleteItem
      .handle({
        accessToken: JSON.parse(localStorage.getItem('@barrosAuth'))
          .accessToken,
        cPedido: orderId,
        cItem: item.dIndice
      })
      .then((res) => toast.success(res.data.message))
      .then(() => dispatch(setRefresh(true)))
      .then(() => {
        try {
          getAmount()
        } catch (err) {
          console.error(err)
        }
      })
      .catch(() => toast.error('Não foi possível remover o produto'))
      .finally(() => setDeleting(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.dIndice, orderId, dispatch])

  return (
    <>
      <tbody key={item.dItemCod}>
        <tr>
          <td colSpan="4">
            <S.Description>{`${item.cFornec} - ${item.dItem}`}</S.Description>
            <S.Application>{item.dGrupo}</S.Application>
          </td>
          <td>
            <Button
              onClick={() => deleteItem()}
              disabled={deleting || finalized}
              color="--color-red"
              style={{ position: 'relative', height: 30 }}
              small
            >
              {deleting ? <Spinner size={16} borderSize={3} /> : <FaTrash />}
            </Button>
          </td>
        </tr>
        <tr>
          <S.TdAmount>
            <div style={{ textAlign: 'start' }}>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Input
                disabled={finalized || changing}
                type="number"
                name="amount"
                readOnly={false}
                formik={formik}
              />
              <div>
                <Button
                  disabled={finalized || changing}
                  type="submit"
                  color="--color-white"
                  borderColor="--color-dark-grey"
                  colorFont="--color-dark-grey"
                  block
                >
                  {changing ? 'Alterando...' : 'Alterar'}
                </Button>
              </div>
            </form>
          </S.TdAmount>

          <td colSpan={2}>
            {toMoney(
              Number(item.vUnitario) +
                (Number(item.vUnitario) / 100) *
                  (item?.bPromocao ? 0 : promotionPercentage)
            )}
          </td>
          <td>
            {toMoney(
              Number(item.vTotItem) +
                (Number(item.vTotItem) / 100) *
                  (item?.bPromocao ? 0 : promotionPercentage)
            )}
          </td>
        </tr>
        {formik.errors.amount && (
          <tr>
            <td colSpan="4" style={{ textAlign: 'left' }}>
              {typeof formik?.errors?.amount === 'string' ? (
                <ErrorMessage>{formik.errors.amount}</ErrorMessage>
              ) : (
                formik?.errors?.amount.map((error) => (
                  <ErrorMessage key={error.Description}>
                    {error.Description}
                  </ErrorMessage>
                ))
              )}
            </td>
          </tr>
        )}
        <tr>
          <S.Divider colSpan="5"></S.Divider>
        </tr>
      </tbody>
    </>
  )
}
