import * as S from './styles'

export const Link = ({
  children,
  color,
  colorFont,
  borderColor,
  fontSize,
  block,
  small,
  to,
  onClick,
  disabled,
  ...rest
}) => {
  return (
    <S.Button
      disabled={disabled}
      color={color}
      colorFont={colorFont}
      borderColor={borderColor}
      fontSize={fontSize}
      block={block}
      small={small}
      to={to ? to : ''}
      onClick={onClick}
      {...rest}
    >
      {children}
    </S.Button>
  )
}
