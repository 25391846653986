import styled from 'styled-components'

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const Wrapper = styled(InputGroup)`
  & input {
    font-size: 16px;
    padding: 8px;
    margin-top: 8px;
    border-radius: 5px;
    color: var(--color-dark-grey);
    border: 2px solid var(--color-dark-grey);
  }
  & input:focus {
    outline: 0;
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  & div {
    color: var(--color-red);
    height: 8px;
    margin: 0 0 4px 16px;
    font-size: 12px;
  }
`

export const Input = styled.input`
  font-size: 16px;
  padding: 8px;
  margin-right: 8px;
  width: 100%;
  border-radius: 5px;
  color: var(--color-dark-grey);
  border: 2px solid var(--color-dark-grey);
  &:focus {
    outline: 0;
  }
`

export const Select = styled.select`
  border: 1px solid var(--color-font);
  font-size: 16px;
  padding: 7px;
  margin-top: 8px;
  color: #000;
  width: 100%;
`

export const LabelSelect = styled(InputGroup)`
  & select {
    border: 2px solid #000;
    font-size: 16px;
    padding: 7px;
    margin-top: 8px;
    color: #000;
    background-color: #fff;
  }
  & select:focus {
    outline: 0;
  }
  & option {
    line-height: 20px;
  }
`
