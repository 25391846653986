import GlobalStyle from 'global-styles/global-styles'
import store from 'application/store'
import { Provider } from 'react-redux'
import { Router } from 'routes/router'
import { ToastContainer } from 'react-toastify'
import { ProductsProvider } from 'application/contexts/products'

export const App = () => {
  return (
    <Provider store={store}>
      <ProductsProvider>
        <ToastContainer autoClose={5000} />
        <GlobalStyle />
        <Router />
      </ProductsProvider>
    </Provider>
  )
}
