import { api } from 'infra/adapters/http-client-adapter'

export const getPromotionTypes = async (params) => {
  return await api.get('/Itens/TipoPromocao', { params })
}

export const getPromotionItens = async (params) => {
  const customer = Number(JSON.parse(localStorage.getItem('@barrosAuth'))?.clientsId)

  return await api.get('/itens/itenspromocao', {
    params: {
      ...params,
      Clientesid: customer,
      GruposId: 0,
      CategProdId: 0,
      SubcategId: 0,
    }
  })
}

export const getBanners = async () => {
  const token = JSON.parse(localStorage.getItem('@barrosAuth'))

  const params = {
    cCliente: Number(token?.clientsId)
  }

  return await api.get(`/banners/getBanner`, { params })
}
