import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`

export const WrapperCards = styled.div`
  width: auto;
`
export const Carousel = styled.div`
  border-radius: 3px;
  min-width: 300px;
  width: 100%; ;
`
export const Image = styled.div`
  height: 100%;
  padding: 3px 3px 20px 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;

  & img {
    max-height: 500px;
    // width: 100%;
    border-radius: 3px;
  }
`
export const Info = styled.div`
  width: 100%;

  & h2 {
    margin: 0 0 15px 0;
  }

  & p {
    margin-bottom: 10px;
  }
`
export const BuyBox = styled.div`
  display: flex;
  width: 100%;
  min-width: 300px;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 2px;
  padding: 15px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

  & p {
    margin: 10px 0;
  }
`

export const SeeMore = styled.a`
  color: #3483fa;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
  text-transform: none;

  &:hover {
    color: #0e5dd3;
  }
`

export const ProductCardPrice = styled.div`
  margin: 10px 0;
  & p {
    font-weight: 700;
    color: var(--color-font);
    font-size: 24px;
    margin: 0;
  }
`
export const CardPrice = styled.h1`
  font-size: 40px;
  font-weight: 500;
`

export const CardPriceOld = styled.h3`
  color: rgb(100, 100, 100);
  font-weight: 500;
`

export const CardBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`

export const CardInfo = styled.p`
  font-size: 15px;
  margin: 3px 0;
  color: ${(props) => props.color ?? 'black'};
`

export const CardConfirmBuy = styled.div`
  display: flex;
  margin: 0 0 10px 0;

  & span {
    margin-right: 10px;
  }
`

export const CardInput = styled.input`
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;

  &:focus {
    outline: none;
  }
`

export const CardButton = styled.button`
  cursor: pointer;
  background: ${({ background, contained }) =>
    contained ? background || 'transparent' : 'transparent'};
  color: ${({ color }) => (color ? color : '#333')};
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: ${({ contained, color }) =>
    contained ? 'none' : '1px solid ' + (color ? color : '#333')};
  transition: all 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(0.6);
  }

  &:disabled {
    /* color: #9c9c9c; */
    /* outline-color: #9c9c9c; */
    /* background-color: #ebebeb; */
    filter: none;
    cursor: default;
  }

  &:disabled:hover {
    /* color: #9c9c9c; */
    /* outline-color: #9c9c9c; */
    /* background-color: #ebebeb; */
    filter: none;
  }
`

export const PercentPromotion = styled.h5`
  height: 22px;
  width: auto;
  max-width: 60px;
  font-size: 12px;
  color: white;
  background-color: green;
  padding: 4px 6px;
  border-radius: 5px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  & label {
    margin-bottom: 5px;
  }

  & p {
    margin: 5px 0;
  }
`
export const CarouselContainer = styled.div`
  position: relative;

  background-color: transparent;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 768px) {
    & .slick-slider {
      grid-template-columns: 100%;
    }
  }
`

export const Carrousel = styled.div`
  display: flex;
  overflow-x: hidden;
  gap: 15px;
  transition: 0.5s;
`

export const ArrowLeft = styled.button`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.3s;
  left: 0;
  top: ${({ notShouldCenter }) => (notShouldCenter ? '' : '50%')};
  transform: 'translateY(-50%)';
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #3d3d3d95;
  font-size: 25px;
  border-radius: 70px;
  z-index: 999;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #c41104;
  }
`
export const ArrowRight = styled.button`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.3s;
  right: 0;
  top: ${({ notShouldCenter }) => (notShouldCenter ? '' : '50%')};
  transform: 'translateY(-50%)';
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #3d3d3d95;
  font-size: 25px;
  border-radius: 70px;
  z-index: 999;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #c41104;
  }
`
